<template>
    <div class="customers" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">{{ getMyLanguage("customers", "customers.title") }}</h3>
                
                <div class="search-bar" v-if="dataCustomers.length && !dataCustomersReview.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataCustomers != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataCustomers) }}</p> -->
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataCustomers}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataCustomers.length && !errorsGet.dataCustomers">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" :class="{reviews: dataCustomersReview.length}" v-if="showContent && dataCustomers.length && !errorsGet.dataCustomers">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <div class="filter box" v-if="filterCustumers">
                                <div class="close-filter" @click="filterCustumers = false"></div>
                                
                                <div class="filters">
                                    <div class="form-group-switch">
                                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                        <label for="stage" class="form-switch-label">
                                            <small>Filter name</small>
                                        </label>
                                    </div>
                                </div>

                                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
                            </div>
                            
                            <div class="search-bar" v-if="!filterCustumers && dataCustomersReview.length">
                                <input type="text" class="form form-search" id="searchCustomer" v-on:keyup="filterCustumer" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                                <div class="btn-filter" @click="filterCustumers = true"></div>
                            </div>

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","created") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="hasPurchase">{{ getMyLanguage("list-result","purchase") }}</th>
                                        <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                        <th class="country">{{ getMyLanguage("list-result","country") }}</th>
                                        <th class="language">{{ getMyLanguage("list-result","language") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","statusKYC") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(customersList,index) in dataCustomers" :key="index" @click="viewCustomer(index)">
                                        <td class="date"><span>{{dateTime(customersList.account_created_at)}}</span></td>
                                        <td class="fullName text-left"><span>{{customersList.profile_full_name}}</span></td>
                                        <td class="hasPurchase">
                                            <span v-if="!customersList.has_purchase">- -</span>
                                            <span v-if="customersList.has_purchase"><img src="~@/assets/images/icons/has-purchase.svg"></span>
                                        </td>
                                        <td class="type"><span>{{ getMyLanguage("list-result", customersList.account_type) }}</span></td>
                                        <td class="country"><span>{{customersList.profile_country_name}}</span></td>
                                        <td class="language"><span>{{customersList.account_language_name}}</span></td>
                                        <td class="status tooltip" :class="customersList.account_status">
                                            <span>{{ getMyLanguage("status",customersList.account_status) }}</span>
                                            <span class="tooltip">{{ getMyLanguage("customers","tooltip." + customersList.account_status) }}</span>
                                        </td>
                                        <td class="status" :class="{Active: customersList.kyc, PendingVerification: !customersList.kyc}"><span>{{ getMyLanguage("status","kyc." + customersList.kyc) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- DATA REVIEW -->
                    <div class="support" v-if="showContent && dataCustomersReview.length">
                        <div class="box">
                            <div class="scroll scroll-horizontal">
                                <h3 class="title-secondary">{{getMyLanguage("labels","label.data-review")}}</h3>

                                <div class="search-bar">
                                    <input type="text" class="form form-search" id="searchCustomerReview" v-on:keyup="filterCustumersReview" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                                    <div class="btn-filter" @click="filterCustumersReview = true"></div>
                                </div>
                                
                                <table class="list-result">
                                    <tbody id="drop-list-review">
                                        <tr class="list" v-for="(customersList,index) in dataCustomersReview" :key="index" @click="viewCustomer(index)">
                                            <td class="fullName text-left"><span>{{customersList.profile_full_name}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



    <!-- MODAL FILTER -->
    <div class="modal" v-if="filter">
        <div class="close-modal" @click="filter = false"></div>
        <div class="box cont-modal">
            <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
            <div class="filters">
                <div class="form-group-switch">
                    <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                    <label for="stage" class="form-switch-label">
                        <small>Filter name</small>
                    </label>
                </div>
            </div>

            <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
        </div>
    </div>
    <div class="overlayModal" v-if="filter"></div>
            


    <!-- MODAL -->
    <div class="modal" v-if="modalShow">
        <div @click="closeModal()" class="close-modal"></div>

        <div class="box cont-modal">
            <div class="load" v-if="!contModalLoaded"></div>
            
            <div class="scroll-vertical" v-if="contModalLoaded">
                <h3>
                    {{dataModal.profile_full_name}}
                    <small>{{dataModal.referrer_name}}</small>
                </h3>

                <div class="resume-box resume-box-person" v-if='dataModal.account_type === "Person"'>

                    <!-- RESUME CLIENT -->
                    <div class="alert" v-if='dataModal.account_status != "Verified"'>{{getMyLanguage("customers","alert.register")}}</div>
                    <div class="box resume" :class="{Verified: dataModal.kyc, Pending: !dataModal.kyc}">

                        <p><strong>{{ getMyLanguage("list-result","email") }}: </strong>{{dataModal.profile_email}}</p>
                        <p><strong>{{ getMyLanguage("list-result","phone") }}: </strong>+{{dataModal.profile_phone_country_ddi}} {{dataModal.profile_phone_number}}</p>
                        <!-- <p><strong>{{ getMyLanguage("list-result","DOB") }}: </strong>{{dateTime(dataModal.personDOB)}}</p> -->
                        <p><strong>{{ getMyLanguage("list-result","language") }}: </strong>{{dataModal.account_language_name}}</p>
                        <p><strong>{{ getMyLanguage("list-result","country") }}: </strong>{{dataModal.profile_country_name}}</p>

                        <!-- <p class="address">Endereço completo</p> -->

                        <!-- <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{dataModal.account_status}}</p>
                        <p>
                            <strong>{{ getMyLanguage("list-result","statusKYC") }}: </strong>
                            <span v-if="dataModal.account_profile_status">{{getMyLanguage("compliance","compliance.lbl.kyc.active")}}</span>
                            <span v-if="!dataModal.account_profile_status">{{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}</span>
                        </p> -->
                        <!-- <p><strong>{{ getMyLanguage("list-result","taxNumber") }}: </strong>{{dataModal.personTaxNumber}}</p> -->
                        <!-- <p><strong>{{ getMyLanguage("list-result","gender") }}: </strong>{{dataModal.personGender}}</p> -->
                    </div>
                </div>


                <!-- STATUS -->
                <div class="resume-box-status" v-if='dataModal.account_type === "Person"'>
                    <h3>Compliance</h3>
                    <div class="load" v-if="!contModalCompliance.load"></div>
                    <div class="resume-status" v-if="contModalCompliance.load">
                        <!-- <div class="status account" :class="dataModal.account_status">
                            <i></i>
                            <span v-html="getMyLanguage('list-result','status')"></span>
                        </div>
                        
                        <div class="status kyc" :class="dataModal.account_profile_status">
                            <i></i>
                            <span v-html="getMyLanguage('list-result','statusKYC')"></span>
                        </div>

                        <div class="status phone" :class="dataModal.profile_phone_number_verified ? 'Verified' : 'Pending'">
                            <i></i>
                            <span v-html="getMyLanguage('list-result','phone')"></span>
                        </div> -->

                        <div class="status" :class="dataModal.account_status ? 'Verified' : 'Pending'"><i></i><strong>E-mail</strong></div>
                        <div class="status" :class="dataModal.profile_phone_number_verified ? 'Verified' : 'Pending'"><i></i><strong>Telefone</strong></div>
                        <div class="status" :class="dataModal.kyc ? 'Verified' : 'Pending'"><i></i><strong>KYC</strong></div>
                        <div class="status Verified"><i></i><strong>AML</strong></div>

                        <a :href="contModalCompliance.link" target="_blank" class="status Verified" v-if="dataModalCompliance.signed"><i></i><strong>UBO</strong></a>
                        <div class="status Pending" v-if="!dataModalCompliance.signed"><i></i><strong>UBO</strong></div>

                        <div class="status Verified"><i></i><strong>Validade</strong></div>
                        <div class="status Verified"><i></i><strong>Endereço</strong></div>
                    </div>
                </div>

                <!-- <pre>{{dataModal}}</pre> -->


                <!-- RESUME COMPANY -->
                <div class="resume-box resume-box-company" v-if='dataModal.account_type === "Company"'>
                    <!-- <div class="alert" v-if='!dataModal.documentsValidated'>A empresa está com documentos pendentes.</div> -->

                    <!-- COMPANY -->
                    <div class="box resume resume-company" :class="dataModal.account_status">
                        <p><strong>{{ getMyLanguage("list-result","email") }}: </strong>{{dataModal.profile_email}}</p>
                        <p><strong>{{ getMyLanguage("list-result","phone") }}: </strong>+{{profile_phone_country_ddi}} {{dataModal.profile_phone_number}}</p>
                        <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{dataModal.account_status}}</p>
                        <p><strong>{{ getMyLanguage("list-result","country") }}: </strong>{{dataModal.profile_country_name}}</p>
                        <p><strong>{{ getMyLanguage("list-result","language") }}: </strong>{{dataModal.account_language_name}}</p>
                    </div>


                    <!-- STATUS -->
                    <div class="resume-box-status">
                        <h3>Compliance</h3>
                        <div class="load" v-if="!contModalCompliance.load"></div>
                        <div class="resume-status" v-if="contModalCompliance.load">
                            <!-- <div class="status account" :class="dataModal.account_status">
                                <i></i>
                                <span v-html="getMyLanguage('list-result','status')"></span>
                            </div>
                            
                            <div class="status kyc" :class="dataModal.account_profile_status">
                                <i></i>
                                <span v-html="getMyLanguage('list-result','statusKYC')"></span>
                            </div>

                            <div class="status phone" :class="dataModal.profile_phone_number_verified ? 'Verified' : 'Pending'">
                                <i></i>
                                <span v-html="getMyLanguage('list-result','phone')"></span>
                            </div> -->

                            <div class="status" :class="dataModal.account_status ? 'Verified' : 'Pending'"><i></i><strong>E-mail</strong></div>
                            <div class="status" :class="dataModal.profile_phone_number_verified ? 'Verified' : 'Pending'"><i></i><strong>Telefone</strong></div>
                            <div class="status" :class="dataModal.account_status ? 'Verified' : 'Pending'"><i></i><strong>KYB</strong></div>
                            <div class="status Verified"><i></i><strong>AML</strong></div>

                            <a :href="contModalCompliance.link" target="_blank" class="status Verified" v-if="dataModalCompliance.signed"><i></i><strong>UBO</strong></a>
                            <div class="status Pending" v-if="!dataModalCompliance.signed"><i></i><strong>UBO</strong></div>

                            <div class="status Verified"><i></i><strong>Validade</strong></div>
                            <div class="status Verified"><i></i><strong>Endereço</strong></div>
                        </div>
                    </div>

                    <!-- <pre>{{dataModal}}</pre> -->


                    <!-- PARTNERS -->
                    <div v-for="(listPartners,index) in dataModalPartners.partners" :key="index">
                        <h3>{{listPartners.fullName}}</h3>
                        <div class="alert" v-if='!listPartners.kyc'>{{getMyLanguage("customers","alert.register")}}</div>
                        
                        <div class="box resume resume-partners" :class="{Verified: listPartners.kyc, Pending: !listPartners.kyc}">
                            <p><strong>{{ getMyLanguage("list-result","email") }}: </strong>{{listPartners.email}}</p>
                            <p><strong>{{ getMyLanguage("list-result","phone") }}: </strong>+{{listPartners.profile_phone_country_ddi}} {{listPartners.phoneNumber}}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{listPartners.account_status}}</p>
                            <p>
                                <strong>{{ getMyLanguage("list-result","statusKYC") }}: </strong>
                                <span v-if="listPartners.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.active")}}</span>
                                <span v-if="!listPartners.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}</span>
                            </p>
                            <p><strong>{{ getMyLanguage("list-result","country") }}: </strong>{{listPartners.country}}</p>
                            <p><strong>{{ getMyLanguage("list-result","language") }}: </strong>{{listPartners.language}}</p>
                        </div>
                    </div>
                    
                </div>


                <!-- PURCHASES -->
                <div class="resume-box purchasesClient" v-if="dataModal.has_purchase">
                    <h3>{{ getMyLanguage("customers","modal.purchases") }}</h3>
                    <div class="box resume purchases" v-for="(listModalSales, index) in dataModalSales" :key="index" :class="listModalSales.phase_purchase_status">
                        <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{ dateTime(listModalSales.created_at) }}</p>
                        <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status",listModalSales.phase_purchase_status) }}</p>
                        <p><strong>{{ getMyLanguage("list-result","quantity") }}: </strong>{{listModalSales.quantity}}</p>
                        <p><strong>{{ getMyLanguage("list-result","price") }}: </strong>{{listModalSales.final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p>
                        <p><strong>{{ getMyLanguage("list-result","stake") }}: </strong>{{listModalSales.stake_term}} {{ getMyLanguage("list-result","months") }}</p>
                        <p><strong>{{ getMyLanguage("list-result","phase") }}: </strong>{{listModalSales.phase_name}}</p>
                        <p><strong>{{ getMyLanguage("list-result","paymentType") }}: </strong>{{listModalSales.payment_type}}</p>
                        <p class="statusReason">{{listModalSales.phase_purchase_status_reason}}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="overlayModal" v-if="this.modalShow === true"></div>
    
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },

        data () {
            return {
                flagSelected: {},
                showContent: false,

                contModalLoaded: false,
                contModalCompliance: {
                    load: true,
                    link: ""
                },

                dataCustomers: [],
                dataCustomersReview: [],
                dataModal: [],
                dataModalCompliance: [],
                dataModalPartners: [],
                dataModalSales: [],

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterCustumers: false,
                modalShow: false,

                errorsGet: {
                    dataCustomers: false
                },
                errorsPost: {
                    dataCustomers: false
                }
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/account/profiles')
            .then(response => {
                this.dataCustomers = response.data
                this.dataCustomersReview = this.dataCustomers.filter(paymentTypes => paymentTypes.account_status === "PendingVerifications")

            })
            .catch(error => {
                this.errorsGet.dataCustomers = error.response.status

                if ( this.errorsGet.dataCustomers == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataCustomers == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewCustomer(value){
                this.dataModal = this.dataCustomers[value]
                this.modalShow = true

                this.checkCompliance(this.dataModal.account_type_code,this.dataModal.account_id)

                if ( this.dataModal.account_type_code === "account_type_company" ){
                    this.checkAccountType(this.dataModal.account_id)
                }
                
                if ( this.dataModal.has_purchase ){
                    this.checkPurchase(this.dataModal.account_id)
                }

                setTimeout(() => {
                    this.contModalLoaded = true
                }, 500);
            },

            async checkAccountType(value){
                const resumePartners = await apiBackoffice.get('/api/v1/international/compliance/kyc/' + value)
                this.dataModalPartners = resumePartners.data
            },

            async checkCompliance(type,value){
                let accountType
                if ( type == "account_type_company" ){
                    accountType = "company"
                    this.contModalCompliance.link = `/${this.flagSelected}/international/customers/compliance/ubo/company/${value}`
                } else{
                    accountType = "person"
                    this.contModalCompliance.link = `/${this.flagSelected}/international/customers/compliance/ubo/person/${value}`
                }

                const resumeCompliance = await apiBackoffice.get(`/api/v2/platform/compliance/ubo/${accountType}/${value}`)
                this.dataModalCompliance = resumeCompliance.data
                this.contModalCompliance.load = true
            },
            
            async checkPurchase(value){
                const resumeSales = await apiBackoffice.get('/api/v1/international/sales/purchases/account/' + value)
                this.dataModalSales = resumeSales.data
            },


            closeModal(){
                this.modalShow = false
                this.contModalLoaded = false
                this.contModalCompliance.load = true
                this.contModalCompliance.link = ""
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-customers.scss" lang="scss" scoped />