<template>
    <div class="agents" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">{{ getMyLanguage("parameters", "parameters.title") }}</h3>
               
                <div class="box box-construction">
                    <p>{{getMyLanguage("construction","txt.construction")}}</p>
                </div>
            </div>
        </div>
            


        <!-- MODAL -->
        <div class="modal" v-if="this.modalShow === true">
            <div @click="(this.modalShow = false),(cancel())" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    <h3 v-if="flowModal.resume && flowModal.actions">{{dataModal.name}}</h3>

                    <div class="resume-box">

                        <!-- RESUME -->
                        <div class="alert" v-if='dataModal.status != "Activated"'>O cliente ainda não concluiu todas as etapas de registo.</div>
                        <div class="box resume" :class="dataModal.status" v-if="flowModal.resume">
                            <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{dataModal.createdAt}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{ getMyLanguage("list-result", dataModal.agentType) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.regionName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","language") }}: </strong>{{dataModal.language}}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.status) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","DOB") }}: </strong>{{dateTime(dataModal.personDOB)}}</p>
                            
                        </div>
                        
                        <div class="box resume-parent">
                            <p><strong>{{ getMyLanguage("list-result","parentAgent") }}: </strong>{{dataModal.parentName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{dataModal.parentAgentType}}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.parentRegionName}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="this.modalShow === true"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    // import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,

                dataSettings: [],
                dataModal: [],

                flowModal: {
                    resume: true,
                    actions: true,
                    edit: false,
                    authorizeKYC: false,
                    inactivate: false
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterAgents: false,
                modalShow: false,

                errorsGet: {
                    dataSettings: false
                },
                errorsPost: {
                    dataSettings: false
                }
            }
        },
        
        async mounted () {
            setTimeout(() => {
                this.showContent = true
            }, 1000);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }       
    }
</script>

<style src="./custom-parameters.scss" lang="scss" scoped />