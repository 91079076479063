<template>
    <div class="sign-register">
        <div class="content-login">
            <div class="cont">
                <video id="dneVideo" class="bg-video" autoplay="autoplay" loop="true" muted defaultmuted playsinline>
                    <source src="/content/dne-token.mp4" type="video/mp4">
                    <source src="/content/dne-token.webm" type="video/webm">
                </video>

                <div class="cont-login">
                    <div class="form-login">
                        <form>
                            <div class="header-login">
                                <img src="@/assets/images/dne-backoffice.svg" >
                                
                                <SelectLanguage />
                            </div>

                            <div class="login-fields" v-if="!stepLogin.load">
                                <!-- EMAIL LOGIN -->
                                <!-- <input v-model="sign.username" type="email" class="form form-email" @keyup.enter="validateForm()" :class='{error: sign.emptyUsername}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.placeholder.email")'> -->
                                <input v-model="sign.username" type="email" class="form form-email" spellcheck="false"
                                :class='{error: stepLogin.emailRegex == "invalid"}'
                                :placeholder='getMyLanguage("login", "login.placeholder.email")'
                                @keyup.enter="validateLogin('login')"
                                @keyup="validateLogin('typingLogin')"
                                @blur="validateLogin('checkEmail')">

                                <!-- PASSWORD -->
                                <div class="enterPass">
                                    <!-- <input v-model="sign.password" type="password" v-if="!showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>
                                    <input v-model="sign.password" type="text" v-if="showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'> -->

                                    <input v-model="sign.password" type="password" class="form form-pass"
                                    :class='{error: stepLogin.password == "invalid"}'
                                    :placeholder='getMyLanguage("login", "login.placeholder.password")'
                                    v-if="!showPass"
                                    @keyup.enter="validateLogin('login')"
                                    @keyup="validateLogin('typingPassword')"
                                    @blur="validateLogin('checkPassword')">

                                    <input v-model="sign.password" type="text" class="form form-pass"
                                    :class='{error: stepLogin.password}'
                                    :placeholder='getMyLanguage("login", "login.placeholder.password")'
                                    v-if="showPass"
                                    @keyup.enter="validateLogin('login')"
                                    @keyup="validateLogin('typingPassword')"
                                    @blur="validateLogin('checkPassword')">

                                    <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                    <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                </div>

                                <!-- <div class="btn-primary btn-disable" v-if="sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.btn.continue") }}</div>
                                <div @click="validateForm()" expand="block" class="btn-primary" v-if="stepLogin.load === false && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login", "login.btn.continue") }}</div>
                                <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div> -->

                                <!-- BTN LOGIN -->
                                <div class="btn-primary btn-disable" v-if="stepLogin.emailRegex == 'invalid' || stepLogin.emailRegex == '' || sign.password.length <= 7">
                                    {{ getMyLanguage("login","login.btn.continue") }}
                                </div>

                                <div @click="validateLogin('login')" expand="block" class="btn-primary" v-if="stepLogin.emailRegex == 'validated' && sign.password.length > 7 && stepLogin.load === false">
                                    {{ getMyLanguage("login", "login.btn.continue") }}
                                </div>

                                <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                            </div>

                            <div class="load-login" v-if="stepLogin.load"></div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import SelectLanguage from '@/components/SelectLanguage.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            SelectLanguage
        },

        data () {
            return {
                flagSelected: {},

                stepLogin: {
                    load: false,
                    emailRegex: "",
                    password: ""
                },

                sign: {
                    username: "",
                    password: ""
                },
                
                showPass: false,
                codeConfirm: {}
            }
        },

        async mounted () {
            setTimeout(() => {
                this.showContent = true
            }, 1000);
        },

        methods: {            
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            validateLogin(type){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                switch (type) {
                    case "checkEmail":
                        if ( regex.test(this.sign.username) ) {
                            this.stepLogin.emailRegex = "validated"
                        } else{
                            this.stepLogin.emailRegex = "invalid"
                        }
                        
                        break;
                    
                    case "checkPassword":
                        if ( this.sign.password.length > 7 ){
                            this.stepLogin.password = "validated"
                        } else {
                            this.stepLogin.password = "invalid"
                        }

                        break;

                    case "login":
                        if ( regex.test(this.sign.username) && this.sign.password.length > 7 ) {
                            this.stepLogin.emailRegex = "validated"
                            this.stepLogin.password = "validated"
                            this.loginBackoffice()
                        } else{
                            this.stepLogin.emailRegex = "invalid"
                        }

                        break;

                    case "typingLogin":
                        this.stepLogin.emailRegex = ""

                        break;

                    case "typingPassword":
                        this.stepLogin.password = ""

                        break;
                
                    default:
                        break;
                }
            },

            loginBackoffice(){
                this.stepLogin.load = true

                apiBackoffice.post("/api/v1/account/login", this.sign)
                .then(response => {
                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);
                    this.$store.commit('loginStatus', true)

                    this.flagSelected = localStorage.getItem('codeLanguage')
                    
                    window.location.href = '/' + this.flagSelected + '/international/customers/'
                })
                .catch(error => {
                    console.clear()
                    console.log(error.message);
                    this.$toast.error(this.getMyLanguage("login","login-password-error"));
                    
                    setTimeout(() => {
                        this.stepLogin.load = false
                    }, 1000);
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-login.scss" lang="scss" scoped />