<template>
    <div class="invites" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("invites","invites.title") }}
                </h3>
                
                <div class="search-bar" v-if="dataInvites.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataInvites != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataInvites) }}</p> -->
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataInvites}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataInvites.length && !errorsGet.dataInvites">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes">
                    <div class="box" v-if="showContent && dataInvites.length && !errorsGet.dataInvites">
                        <div class="scroll scroll-horizontal">
                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","invited-by") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","invitation-to") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","createdAt") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","expiresAt") }}</th>
                                        <th class="type">{{ getMyLanguage("list-result","type") }}</th>
                                        <th class="delivery">{{ getMyLanguage("list-result","delivery") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(listInvite,index) in dataInvites" :key="index">
                                        <td class="fullName text-left" @click="viewSale(index)"><span>{{listInvite.agent_name}}</span></td>
                                        <td class="fullName text-left" @click="viewSale(index)">
                                            <span v-if='listInvite.invite_status != "Generated"'>{{listInvite.account_full_name}}</span>
                                            <span v-if='listInvite.invite_status === "Generated"' class="pending">{{listInvite.invite_description}}</span>
                                        </td>
                                        <td class="date" @click="viewSale(index)"><span>{{dateTime(listInvite.invite_created_at)}}</span></td>
                                        <td class="date" @click="viewSale(index)"><span>{{dateTime(listInvite.invite_expires_at)}}</span></td>
                                        <td class="type" @click="viewSale(index)"><span>{{ getMyLanguage("status", listInvite.invite_type_code) }}</span></td>
                                        <td class="delivery" :class="listInvite.delivery_status">
                                            <div class="status-delivery"></div>
                                            <div class="tooltip-delivery">{{listInvite.delivery_status_reason}}</div>
                                        </td>
                                        <td class="status" @click="viewSale(index)" :class="listInvite.invite_status"><span>{{ getMyLanguage("status", listInvite.invite_status) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
            


        <!-- MODAL -->
        <div class="modal" v-if="modalShow">
            <div @click="closeModal()" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll scroll-horizontal">
                    <h3>
                        <!-- <span v-if='dataModal.account_full_name != ""'>{{dataModal.account_full_name}}</span>
                        <span v-if='dataModal.account_full_name === ""'>- -</span>
                        <small>{{dataModal.agent_name}}</small> -->
                        
                        <span v-if='dataModal.invite_status != "Generated"'>{{dataModal.account_full_name}}</span>
                        <span v-if='dataModal.invite_status === "Generated"'>{{dataModal.invite_description}}</span>
                        <small>{{dataModal.agent_name}}</small>
                    </h3>

                    <div class="resume-box">

                        <!-- RESUME INVITE -->
                        <div class="box resume" :class="dataModal.invite_status">
                            <p><strong>{{ getMyLanguage("list-result","createdAt") }}: </strong>{{dateTimeYear(dataModal.invite_created_at)}}</p>
                            <p><strong>{{ getMyLanguage("list-result","expiresAt") }}: </strong>{{dateTimeYear(dataModal.invite_expires_at)}}</p>
                            <p><strong>{{ getMyLanguage("list-result","type") }}: </strong>{{ getMyLanguage("status", dataModal.invite_type_code) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.invite_status) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","origin") }}: </strong>{{ getMyLanguage("status", dataModal.origin) }}</p>
                            <p v-if="dataModal.account_profile_type_code != null"><strong>{{ getMyLanguage("list-result","profile_type") }}: </strong>{{ getMyLanguage("status", dataModal.account_profile_type_code) }}</p>
                            <!-- <p><strong>{{dataModal.id}}</strong></p> -->

                            <!-- <p><strong>Ref.: </strong>{{dataModal.reference}}</p>
                            
                            <div class="reason">
                                <p><small><strong>Aprovado por: </strong>Eva Maria Rios em 07/07/2022</small></p>
                                <p>O pagamento foi aprovado com a hash informada</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalShow"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                flagSelected: {},
                showContent: false,
                dataInvites: [],

                errorsGet: {
                    dataInvites: false
                },

                dataModal: {
                    load: false,
                    index: "",
                },
                
                filter: false,
                modalShow: false,
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/international/agents/invites')
            .then(response => {
                this.dataInvites = response.data
            })
            .catch(error => {
                this.errorsGet.dataInvites = error.response.status

                if ( this.errorsGet.dataInvites == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataInvites == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },
            dateTimeYear(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },
            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },



            // OPEN MODAL > VIEW SALE
            viewSale(value){
                this.dataModal = this.dataInvites[value]
                this.dataModal.index = value
                
                this.dataModal.modal = true

                this.modalShow = true
            },


            // CLOSE MODAL
            closeModal(){
                this.modalShow = false
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-invites.scss" lang="scss" scoped />