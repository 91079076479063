<template>
    <div class="commissions" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content content-commissions">

                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("commissions","commissions.title") }}
                </h3>
                
                <div class="search-bar" v-if="dataCommissions.length">
                    <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataCommissions != false">
                    <h3 class="title-secondary">{{ getMyLanguage("commissions", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataCommissions) }}</p> -->
                    <p>{{ getMyLanguage("commissions","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataCommissions}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataCommissions.length && !errorsGet.dataCommissions">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" v-if="showContent && dataCommissions.length && !errorsGet.dataCommissions">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","agent") }}</th>
                                        <th class="quantity">{{ getMyLanguage("list-result","quantity") }}</th>
                                        <th class="discount">{{ getMyLanguage("list-result","discount") }}</th>
                                        <th class="bonus">{{ getMyLanguage("list-result","bonus") }}</th>
                                        <th class="price">{{ getMyLanguage("list-result","price") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="price col-highlight first">{{ getMyLanguage("list-result","commission") }}</th>
                                        <th class="date col-highlight">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="status col-highlight">{{ getMyLanguage("list-result","status") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(customersList,index) in dataCommissions" :key="index" @click="viewCustomer(index)">
                                        <td class="date"><span>{{dateTime(customersList.sale_date)}}</span></td>
                                        <td class="fullName text-left"><span>{{customersList.agent_full_name}}<small>{{customersList.profile_name}}</small></span></td>
                                        <td class="quantity"><span>{{customersList.sale_quantity}} {{getMyLanguage("list-result","packs")}}</span></td>
                                        <td class="discount"><span>{{customersList.campaign_discount}}{{getMyLanguage("list-result","percent")}}</span></td>
                                        <td class="bonus"><span>{{customersList.campaign_bonus}}{{getMyLanguage("list-result","percent")}}</span></td>
                                        <td class="price"><span>{{customersList.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td class="status" :class="customersList.sale_status"><span>{{ getMyLanguage("status",customersList.sale_status) }}</span></td>
                                        <td class="price col-highlight first"><span>{{customersList.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td class="date col-highlight"><span>{{dateTime(customersList.commission_pay_day)}}</span></td>
                                        <td class="status col-highlight" :class="customersList.commission_status"><span>{{ getMyLanguage("status",customersList.commission_status) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
        


        <!-- MODAL -->
        <div class="modal" v-if="this.modalShow">
            <div @click="this.modalShow = false" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    
                    <div class="resume-box">

                        <!-- RESUME -->
                        <h3>{{getMyLanguage("commissions","commissions.subtitle.commission")}}</h3>
                        <div class="box resume resume-commission" :class="dataModal.commission_status">
                            <p><strong>{{ getMyLanguage("list-result","agent") }}: </strong>{{dataModal.agent_full_name}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{ getMyLanguage("list-result",dataModal.agent_type) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","value") }}: </strong>{{dataModal.commission_amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p>
                            <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{dateTime(dataModal.commission_pay_day)}}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status",dataModal.commission_status) }}</p>
                        </div>
                        
                        <h3>{{getMyLanguage("commissions","commissions.subtitle.sale")}}</h3>
                        <div class="box resume resume-purchase" :class="dataModal.sale_status">
                            <p><strong>{{dataModal.profile_name}} {{getMyLanguage("commissions","commissions.bought-in")}} {{dateTime(dataModal.sale_date)}}</strong></p>
                            <p><strong>{{ getMyLanguage("list-result","campaign") }}: </strong>{{dataModal.campaign_name}}</p>
                            <p><strong>{{ getMyLanguage("list-result","quantity") }}: </strong>{{dataModal.sale_quantity}}</p>
                            <p><strong>{{ getMyLanguage("list-result","discount") }}: </strong>{{dataModal.campaign_discount}}{{ getMyLanguage("list-result","percent") }}</p>
                            <p><strong>{{ getMyLanguage("list-result","bonus") }}: </strong>{{dataModal.campaign_bonus}}</p>
                            <p><strong>{{ getMyLanguage("list-result","price") }}: </strong>{{dataModal.sale_final_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p>
                            <p><strong>{{ getMyLanguage("list-result","paymentType") }}: </strong>{{dataModal.payment_type}}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status",dataModal.sale_status) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="this.modalShow"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                flagSelected: {},
                showContent: false,

                dataCommissions: [],
                dataModal: [],

                // flowModal: {
                //     resume: true,
                //     actions: true,
                //     edit: false,
                //     authorizeKYC: false,
                //     inactivate: false
                // },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                modalShow: false,

                modalDate: false,

                errorsGet: {
                    dataCommissions: false
                }
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/international/agents/commissions')
            .then(response => {
                this.dataCommissions = response.data
            })
            .catch(error => {
                this.errorsGet.dataCommissions = error.response.status

                if ( this.errorsGet.dataCommissions == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataCommissions == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewCustomer(value){
                this.dataModal = this.dataCommissions[value]
                this.modalShow = true
            },


            
            // openEdit(){
            //     this.flowModal.resume = false
            //     this.flowModal.actions = false
            //     this.flowModal.edit = true
            // },

            // openauthorizeKYC(){
            //     this.flowModal.resume = false
            //     this.flowModal.actions = false
            //     this.flowModal.authorizeKYC = true
            // },
            
            // openInactivate(){
            //     this.flowModal.resume = false
            //     this.flowModal.actions = false
            //     this.flowModal.inactivate = true
            // },

            // cancel(){
            //     this.flowModal.resume = true
            //     this.flowModal.actions = true
            //     this.flowModal.edit = false
            //     this.flowModal.authorizeKYC = false
            //     this.flowModal.inactivate = false
            // }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-commissions.scss" lang="scss" scoped />