import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login/Login.vue'
import Dashboard from '@/views/Dashboard/Dash.vue'
import Customers from '@/views/Customers/Customers.vue'
import UboPerson from '@/views/Customers/UboPerson.vue'
import UboCompany from '@/views/Customers/UboCompany.vue'
import CompliancePerson from '@/views/Compliance/ComplianceCompany.vue'
import ComplianceCompany from '@/views/Compliance/ComplianceCompany.vue'
import Sales from '@/views/Sales/Sales.vue'
import Agreements from '@/views/Sales/Agreements.vue'
import AgreementView from '@/views/Sales/AgreementView.vue'

import Agents from '@/views/SalesForce/Agents.vue'
import Invites from '@/views/SalesForce/Invites.vue'
import Commissions from '@/views/SalesForce/Commissions.vue'
import StatusCommissions from '@/views/SalesForce/StatusCommissions.vue'
import WithdrawalsCommissions from '@/views/SalesForce/Withdrawals.vue'

// import Payments from '@/views/Transactions/Payments.vue'
import Transactions from '@/views/Transactions/Transactions.vue'
import Settings from '@/views/Settings/Settings.vue'
import Parameters from '@/views/Parameters/Parameters.vue'

import Error403 from '@/views/NotFound/Error403.vue'
import NotFound from '@/views/NotFound/Error404.vue'

const routes = [
  { path: '/', redirect: '/en/login/' },
  { path: '/en', redirect: '/en/login/' },
  { path: '/es', redirect: '/es/login/' },
  { path: '/pt', redirect: '/pt/login/' },
  { path: '/ru', redirect: '/ru/login/' },
  { path: '/:lang/login', name: 'Login', component: Login },
  { path: '/:lang/international/dashboard/', name: 'Dashboard', component: Dashboard },
  { path: '/:lang/international/customers/', name: 'Customers', component: Customers },
  { path: '/:lang/international/customers/compliance/ubo/person/:accountId', name: 'UboPerson', component: UboPerson },
  { path: '/:lang/international/customers/compliance/ubo/company/:accountId', name: 'UboCompany', component: UboCompany },
  { path: '/:lang/international/compliance/compliance-person/', name: 'CompliancePerson', component: CompliancePerson },
  { path: '/:lang/international/compliance/compliance-company/', name: 'ComplianceCompany', component: ComplianceCompany },
  { path: '/:lang/international/sales/sales/', name: 'Sales', component: Sales },
  { path: '/:lang/international/sales/agreements/', name: 'Agreements', component: Agreements },
  { path: '/:lang/international/sales/agreement/:registerId', name: 'AgreementView', component: AgreementView },

  { path: '/:lang/international/sales-force/agents/', name: 'Agents', component: Agents },
  { path: '/:lang/international/sales-force/invites/', name: 'Invites', component: Invites },
  { path: '/:lang/international/sales-force/commissions/', name: 'Commissions', component: Commissions },
  { path: '/:lang/international/sales-force/status-commissions', name: 'StatusCommissions', component: StatusCommissions },
  { path: '/:lang/international/sales-force/withdrawals', name: 'WithdrawalsCommissions', component: WithdrawalsCommissions },
  
  // { path: '/:lang/international/payments/', name: 'Payments', component: Payments },
  { path: '/:lang/transactions/', name: 'Transactions', component: Transactions },
  { path: '/:lang/backoffice/settings/', name: 'Settings', component: Settings },
  { path: '/:lang/backoffice/parameters/', name: 'Parameters', component: Parameters },
  
  { path: '/:lang/notfound/error403/', name: 'Error403', component: Error403 },
  { path: '/:lang/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
