<template>
    <div class="agents" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("agents","agents.title") }}
                </h3>
                
                <div class="search-bar" v-if="dataAgents.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataAgents != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataAgents) }}</p> -->
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAgents}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataAgents.length && !errorsGet.dataAgents">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" v-if="showContent && dataAgents.length && !errorsGet.dataAgents">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <div class="filter box" v-if="filterAgents">
                                <div class="close-filter" @click="filterAgents = false"></div>
                                
                                <div class="filters">
                                    <div class="form-group-switch">
                                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                        <label for="stage" class="form-switch-label">
                                            <small>Filter name</small>
                                        </label>
                                    </div>
                                </div>

                                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
                            </div>

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","date") }}</th>
                                        <th class="fullName">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="agentType">{{ getMyLanguage("list-result","agentType") }}</th>
                                        <th class="region">{{ getMyLanguage("list-result","region") }}</th>
                                        <th class="language">{{ getMyLanguage("list-result","language") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="fullName">{{ getMyLanguage("list-result","parentAgent") }}</th>
                                        <th class="agentType">{{ getMyLanguage("list-result","agentType") }}</th>
                                        <th class="region">{{ getMyLanguage("list-result","region") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(agentsList,index) in dataAgents" :key="index" @click="viewCustomer(index)">
                                        <td class="date"><span v-html="dateTime(agentsList.createdAt)"></span></td>
                                        <td class="fullName"><span>{{agentsList.name}}</span></td>
                                        <td class="agentType"><span>{{ getMyLanguage("list-result", agentsList.agentType) }}</span></td>
                                        <td class="region"><span>{{agentsList.regionName}}</span></td>
                                        <td class="language"><span>{{agentsList.language}}</span></td>
                                        <td class="status" :class="agentsList.status"><span>{{ getMyLanguage("status", agentsList.status) }}</span></td>
                                        <td class="fullName"><span>{{agentsList.parentName}}</span></td>
                                        <td class="agentType"><span>{{agentsList.parentAgentType}}</span></td>
                                        <td class="region"><span>{{agentsList.parentRegionName}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
            


        <!-- MODAL -->
        <div class="modal" v-if="this.modalShow">
            <div @click="(this.modalShow = false),(cancel())" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    <h3 v-if="flowModal.resume && flowModal.actions">{{dataModal.name}}</h3>

                    <div class="resume-box">

                        <!-- RESUME -->
                        <div class="alert" v-if='dataModal.status != "Activated"'>O cliente ainda não concluiu todas as etapas de registo.</div>
                        <div class="box resume" :class="dataModal.status" v-if="flowModal.resume">
                            <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{dataModal.createdAt}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{ getMyLanguage("list-result", dataModal.agentType) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.regionName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","language") }}: </strong><span class="text-uppercase">{{dataModal.language}}</span></p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.status) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","DOB") }}: </strong>{{dateTime(dataModal.personDOB)}}</p>
                            
                        </div>
                        
                        <div class="box resume-parent">
                            <p><strong>{{ getMyLanguage("list-result","parentAgent") }}: </strong>{{dataModal.parentName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{dataModal.parentAgentType}}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.parentRegionName}}</p>
                        </div>

                        

                        

                        <!-- ACTION -->
                        <!-- <div class="actions" v-if="flowModal.actions">
                            <ul>
                                <li @click="openEdit()">Editar registo</li>
                                <li @click="openauthorizeKYC()" v-if='(dataModal.KYCStatus != "Approved") && (dataModal.accountStatus === "Verified")'>Autorizar (KYC)</li>
                                <li @click="openInactivate()">Inativar registo</li>
                            </ul>
                        </div> -->


                        <!-- EDIT ACCOUNT -->
                        <div class="edit-account" v-if="flowModal.edit">
                            <h3>
                                Editar registo
                                <small>{{dataModal.personName}} {{dataModal.personSurname}}</small>
                            </h3>

                            <div class="columns">
                                <label>
                                    Campo
                                    <input type="text" class="form" placeholder="abc">
                                </label>
                                <label>
                                    Campo
                                    <input type="text" class="form" placeholder="abc">
                                </label>
                                <label>
                                    Campo
                                    <input type="text" class="form" placeholder="abc">
                                </label>
                                <label>
                                    Campo
                                    <input type="text" class="form" placeholder="abc">
                                </label>
                            </div>

                            <div class="bts">
                                <div class="btn-primary btn-cancel" @click="cancel()">{{getMyLanguage("actions","action.cancel")}}</div>
                                <div class="btn-primary">{{getMyLanguage("actions","action.confirm")}}</div>
                            </div>
                        </div>


                        <!-- AUTHORIZE KYC -->
                        <div class="authorize-kyc" v-if="flowModal.authorizeKYC">
                            <h3>
                                Autorizar (KYC)
                                <small>{{dataModal.personName}} {{dataModal.personSurname}}</small>
                            </h3>
                            
                            <p>*** usaremos link direto ou api para autorizar?</p>

                            <label>
                                Observações
                                <textarea class="form"></textarea>
                            </label>

                            <div class="bts">
                                <div class="btn-primary btn-cancel" @click="cancel()">{{getMyLanguage("actions","action.cancel")}}</div>
                                <div class="btn-primary">{{getMyLanguage("actions","action.confirm")}}</div>
                            </div>
                        </div>


                        <!-- INACTIVATE -->
                        <div class="inactivate" v-if="flowModal.inactivate">
                            <h3>
                                Inativar registo
                                <small>{{dataModal.personName}} {{dataModal.personSurname}}</small>
                            </h3>

                            <label>
                                Motivo da inativação
                                <textarea class="form"></textarea>
                            </label>

                            <div class="bts">
                                <div class="btn-primary btn-cancel" @click="cancel()">{{getMyLanguage("actions","action.cancel")}}</div>
                                <div class="btn-primary">{{getMyLanguage("actions","action.confirm")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modalShow"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                flagSelected: {},
                showContent: false,

                dataAgents: [],
                dataModal: [],

                flowModal: {
                    resume: true,
                    actions: true,
                    edit: false,
                    authorizeKYC: false,
                    inactivate: false
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterAgents: false,
                modalShow: false,

                errorsGet: {
                    dataAgents: false
                },
                errorsPost: {
                    dataAgents: false
                }
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/international/agents')
            .then(response => {
                this.dataAgents = response.data
            })
            .catch(error => {
                this.errorsGet.dataAgents = error.response.status

                if ( this.errorsGet.dataAgents == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataAgents == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },
            
            
            filterCustumer() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchCustomer");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewCustomer(value){
                this.dataModal = this.dataAgents[value]
                this.modalShow = true
            },


            
            openEdit(){
                this.flowModal.resume = false
                this.flowModal.actions = false
                this.flowModal.edit = true
            },

            openauthorizeKYC(){
                this.flowModal.resume = false
                this.flowModal.actions = false
                this.flowModal.authorizeKYC = true
            },
            
            openInactivate(){
                this.flowModal.resume = false
                this.flowModal.actions = false
                this.flowModal.inactivate = true
            },

            cancel(){
                this.flowModal.resume = true
                this.flowModal.actions = true
                this.flowModal.edit = false
                this.flowModal.authorizeKYC = false
                this.flowModal.inactivate = false
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-agents.scss" lang="scss" scoped />