<template>
    <div class="agreement" @keydown.esc="this.modalAgreement.status = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">{{ getMyLanguage("agreements", "agreements.title") }}</h3>
                
                <div class="search-bar" v-if="dataAgreements.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataAgreements != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice", "title-error") }}</h3>
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAgreements}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataAgreements.length && !errorsGet.dataAgreements">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" v-if="showContent && dataAgreements.length && !errorsGet.dataAgreements">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th>{{ getMyLanguage("list-result","created") }}</th>
                                        <th>{{ getMyLanguage("list-result","number") }}</th>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","name") }}</th>

                                        <th>{{ getMyLanguage("list-result","quantityLots") }}</th>
                                        <th>{{ getMyLanguage("list-result","quantityUnits") }}</th>
                                        <th>{{ getMyLanguage("list-result","unitAmount") }}</th>
                                        <th>{{ getMyLanguage("list-result","grossAmount") }}</th>
                                        <th>{{ getMyLanguage("list-result","discount") }}</th>
                                        <th>{{ getMyLanguage("list-result","discountAmount") }}</th>
                                        <th>{{ getMyLanguage("list-result","netAmount") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(agreementsList,index) in dataAgreements" :key="index" @click='purchaseAgreement(agreementsList.id, agreementsList.details.document)'>
                                        <td><span>{{dateTime(agreementsList.details.date)}}</span></td>
                                        <td><span>CHE2022{{String(agreementsList.details.number).padStart(4, '0')}}</span></td>
                                        <td class="fullName text-left"><span>{{agreementsList.recipient.name}}</span></td>
                                        
                                        <td><span>{{agreementsList.agreementItems[1].quantityLots.toLocaleString("pt-PT")}}</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].quantityUnits.toLocaleString("pt-PT")}}</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].unitAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].grossAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].discount}}%</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].discountAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td><span>{{agreementsList.agreementItems[1].netAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



    <!-- MODAL FILTER -->
    <div class="modal" v-if="filter">
        <div class="close-modal" @click="filter = false"></div>
        <div class="box cont-modal">
            <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
            <div class="filters">
                <div class="form-group-switch">
                    <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                    <label for="stage" class="form-switch-label">
                        <small>Filter name</small>
                    </label>
                </div>
            </div>

            <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
        </div>
    </div>
    <div class="overlayModal" v-if="filter"></div>
            


    <!-- MODAL AGREEMENT -->
    <div class="modal modal-agreement" v-if="modalAgreement.status">
        <div class="box cont-modal">
            <div class="close-modal" @click="modalAgreement.status = false,showAgreement = false"></div>
            
            <h2 class="title-secondary">{{getMyLanguage("agreements","modal-agreement.title-registered")}}</h2>
            <a :href="modalAgreement.lnkBlockChain" target="_blank" class="lnk_blockchain"><small>{{modalAgreement.idBlockChain}}</small></a>

            <div class="load" v-if="!showAgreement"></div>

            <div class="cont-iframe" :class="{active: showAgreement}">
                <iframe :src="urlAgreement" frameborder="0"></iframe>
            </div>
        </div>
    </div>
    <div class="overlayModal" v-if="modalAgreement.status"></div>

    
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                flagSelected: {},
                showContent: false,
                dataAgreements: [],

                modalAgreement: {
                    status: false,
                    idBlockChain: "",
                    lnkBlockChain: ""
                },

                urlAgreement: "",
                showAgreement: false,

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterSales: false,

                errorsGet: {
                    dataAgreements: false
                },
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1//international/sales/agreements')
            .then(response => {
                this.dataAgreements = response.data
            })
            .catch(error => {
                this.errorsGet.dataAgreements = error.response.status

                if ( this.errorsGet.dataAgreements == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataAgreements == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");

                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[2];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }
                }
            },

            // purchaseAgreement(idAgreement, idBlockChain, lnkBlockChain) {
            purchaseAgreement(idAgreement, idBlockChain) {
                this.modalAgreement.status = true;
                this.modalAgreement.idBlockChain = idBlockChain
                // this.modalAgreement.lnkBlockChain = lnkBlockChain
                this.modalAgreement.lnkBlockChain = "https://ipfs.io/ipfs/" + idBlockChain + "?filename=Agreement_DNE_International.pdf"
                this.urlAgreement = "/pt/international/sales/agreement/" + idAgreement;

                setTimeout(() => {
                    this.showAgreement = true
                }, 1000);
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-agreements.scss" lang="scss" scoped />