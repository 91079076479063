<template>
    <div class="commissions" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content content-commissions">

                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("menu","status-commissions") }}
                </h3>
                
                <div class="search-bar" v-if="dataCommissions.length">
                    <input type="text" class="form form-search" id="searchLead" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load load-green" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataCommissions != false">
                    <h3 class="title-secondary">{{ getMyLanguage("commissions", "title-error") }}</h3>
                    <!-- <p>{{ getMyLanguage("box-error", errorsGet.dataCommissions) }}</p> -->
                    <p>{{ getMyLanguage("commissions","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataCommissions}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataCommissions.length && !errorsGet.dataCommissions">{{ getMyLanguage("backoffice","no-data") }}</div>

                <div class="boxes" v-if="showContent && dataCommissions.length && !errorsGet.dataCommissions">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="fullName text-left">{{ getMyLanguage("list-result","agent") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="quantity">{{ getMyLanguage("list-result","quantity") }}</th>
                                        <th class="amount">{{ getMyLanguage("list-result","sales-amount") }}</th>
                                        <th class="amount">{{ getMyLanguage("list-result","commission-amount") }}</th>
                                    </tr>
                                </thead>

                                <tbody id="drop-list">
                                    <tr class="list" v-for="(customersList,index) in dataCommissions" :key="index">
                                        <td class="fullName text-left"><span>{{customersList.AgentFullName}}</span></td>
                                        <td class="status" :class="customersList.CommissionStatusExtra"><span>{{ getMyLanguage("status",customersList.CommissionStatusExtra) }}</span></td>
                                        <td class="quantity" :class="customersList.CommissionStatusExtra">
                                            <span>
                                                {{customersList.SalesQuantity}}
                                                <span v-if="customersList.SalesQuantity === 1">{{getMyLanguage("list-result","sale")}}</span>
                                                <span v-if="customersList.SalesQuantity > 1">{{getMyLanguage("list-result","sales")}}</span>
                                            </span>
                                        </td>
                                        <td class="amount" :class="customersList.CommissionStatusExtra"><span>{{customersList.SalesAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                        <td class="amount" :class="customersList.CommissionStatusExtra"><span>{{customersList.CommissionAmount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                flagSelected: {},
                showContent: false,

                dataCommissions: [],

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                modalShow: false,

                errorsGet: {
                    dataCommissions: false
                }
            }
        },

        async mounted () {
            await apiBackoffice.get('/api/v1/sales/commission')
            .then(response => {
                this.dataCommissions = response.data
            })
            .catch(error => {
                this.errorsGet.dataCommissions = error.response.status

                if ( this.errorsGet.dataCommissions == 401 ){
                    localStorage.removeItem('accessToken');
                    this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                    
                } else if ( this.errorsGet.dataCommissions == 403 ){
                    this.$router.push({ name: 'Error403', params: { lang: this.$route.params.lang } })
                }
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);

            // this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("searchLead");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-status-commissions.scss" lang="scss" scoped />