<template>
    <div class="sidebar box">
        <div class="cont-sidebar scroll-small">
            
            <div class="row-header">
                <div class="openMenu" @click="opened = true"></div>
                
                <div class="logo-dne" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
                    <img src="@/assets/images/dne.svg">
                    <div class="backoffice">
                        <h5><span>Back</span>Office</h5>
                        <div class="user">{{$store.state.profile.profile_full_name}}</div>
                        {{$store.state.code}}
                    </div>
                </div>

                <SelectLanguage />
            </div>

            <div class="menu" id="nav" :class="{active: opened}">
                <a :href="'/' + flagSelected + '/international/dashboard/'" class="lnk lnk-dashboard"><span>{{ getMyLanguage("menu", "dashboard") }}</span></a>
                <a :href="'/' + flagSelected + '/international/customers/'" class="lnk lnk-customers"><span>{{ getMyLanguage("menu", "customers") }}</span></a>
                
                <div class="lnk lnk-compliance lnk-submenu" :class="{active: submenu.url.groupCompliance, active: submenu.compliance}" @click='openSubmenu("compliance")'><span>{{ getMyLanguage("menu", "compliance") }}</span></div>
                <div class="submenu" v-if='submenu.compliance'>
                    <!-- <router-link :to="{ name: 'CompliancePerson' }" class="subLnk"><span>{{ getMyLanguage("menu", "compliance.person") }}</span></router-link> -->
                    <a :href="'/' + flagSelected + '/international/compliance/compliance-company/'" class="subLnk"><span>{{ getMyLanguage("menu", "compliance.company") }}</span></a>
                </div>
                
                <div class="lnk lnk-sales lnk-submenu" :class="{active: submenu.url.groupSales, active: submenu.sales}" @click='openSubmenu("sales")'><span>{{ getMyLanguage("menu", "sales") }}</span></div>
                <div class="submenu" v-if='submenu.sales'>
                    <a :href="'/' + flagSelected + '/international/sales/sales'" class="subLnk"><span>{{ getMyLanguage("menu", "sales") }}</span></a>
                    <a :href="'/' + flagSelected + '/international/sales/agreements'" class="subLnk"><span>{{ getMyLanguage("menu", "agreements") }}</span></a>
                </div>

                <div class="lnk lnk-salesForce lnk-submenu" :class="{active: submenu.url.groupSalesForce, active: submenu.salesForce}" @click='openSubmenu("salesForce")'><span>{{ getMyLanguage("menu", "salesForce") }}</span></div>
                <div class="submenu" v-if="submenu.salesForce">
                    <a :href="'/' + flagSelected + '/international/sales-force/invites/'" class="subLnk"><span>{{ getMyLanguage("menu", "invites") }}</span></a>
                    <a :href="'/' + flagSelected + '/international/sales-force/agents/'" class="subLnk"><span>{{ getMyLanguage("menu", "agents") }}</span></a>
                    <a :href="'/' + flagSelected + '/international/sales-force/commissions/'" class="subLnk"><span>{{ getMyLanguage("menu", "commissions") }}</span></a>
                    <a :href="'/' + flagSelected + '/international/sales-force/status-commissions/'" class="subLnk"><span>{{ getMyLanguage("menu", "status-commissions") }}</span></a>
                    <a :href="'/' + flagSelected + '/international/sales-force/withdrawals/'" class="subLnk"><span>{{ getMyLanguage("menu", "withdrawals") }}</span></a>
                </div>

                
                <!-- <router-link :to="{ name: 'Transactions' }" class="lnk lnk-transactions"><span>{{ getMyLanguage("menu", "transactions") }}</span></router-link> -->
                <!-- <router-link :to="{ name: 'Settings' }" class="lnk lnk-settings"><span>{{ getMyLanguage("menu", "settings") }}</span></router-link> -->
                <!-- <router-link :to="{ name: 'Parameters' }" class="lnk lnk-parameters"><span>{{ getMyLanguage("menu", "parameters") }}</span></router-link> -->

                <div class="lnk lnk-logout" @click="logoutPlatform()"><span>{{ getMyLanguage("menu","logout") }}</span></div>
            </div>
        </div>

        <div class="overlaymenu" v-if="opened" @click="opened = false"></div>
    </div>
</template>


<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import SelectLanguage from '@/components/SelectLanguage.vue'

    // import apiBackoffice from '@/config/apiBackoffice.js';

    export default {
        components: {
            SelectLanguage
        },

        data () {
            return {
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,

                load: true,
                flagSelected: {},
                showContent: false,
                opened: false,

                submenu: {
                    sales: false,
                    compliance: false,
                    salesForce: false,

                    url: {
                        groupSales: false,
                        groupCompliance: false,
                        groupSalesForce: false
                    }
                }
            }
        },

        async mounted () {
            this.checkSubmenu()
            
            // await apiBackoffice.get('/api/v1/platform/account/agent')
            // .then(response => {
            //     this.dataAgent = response.data
            //     this.showContent = true
            // })
            // .catch(error => {
            //     if (error.response.status === 401) {
            //         localStorage.removeItem('accessToken');
            //         window.location.href = '/'
            //     }
            // })

            this.load = false
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            lnkDash(){
                window.location.href = this.flagSelected + '/dashboard/'
            },

            openSubmenu(value){
                if ( value === "sales" && !this.submenu.sales ){
                    this.submenu.sales = true
                } else if ( value === "sales" && this.submenu.sales ) {
                    this.submenu.sales = false
                }
                
                if ( value === "compliance" && !this.submenu.compliance ){
                    this.submenu.compliance = true
                } else if ( value === "compliance" && this.submenu.compliance ) {
                    this.submenu.compliance = false
                }
                
                if ( value === "salesForce" && !this.submenu.salesForce ){
                    this.submenu.salesForce = true
                } else if ( value === "salesForce" && this.submenu.salesForce ) {
                    this.submenu.salesForce = false
                }
            },

            checkSubmenu(){
                const urlSubmenu = String(window.location.pathname).split("/")[3]

                if ( urlSubmenu == "compliance"){
                    this.submenu.url.groupCompliance = true
                    this.submenu.compliance = true
                }
                
                if ( urlSubmenu == "sales"){
                    this.submenu.url.groupSales = true
                    this.submenu.sales = true
                }
                
                if ( urlSubmenu == "sales-force"){
                    this.submenu.url.groupSalesForce = true
                    this.submenu.salesForce = true
                }
            },

            logoutPlatform(){
                localStorage.removeItem('accessToken')
                this.$router.push({ name: 'Login', params: { lang: this.$route.params.lang } })
                // window.location.href = this.flagSelected + '/login/'
            },

            menuActive() {
                setTimeout(() => {
                    var nav = document.getElementById("nav"),
                        anchor = nav.getElementsByTagName("a"),
                        current = window.location;

                    for (var i = 0; i < anchor.length; i++) {
                        if (anchor[i].href === current.href) { // Compare a URL completa, não apenas o objeto window.location
                            anchor[i].classList.add("active");
                        } else {
                            anchor[i].classList.remove("active");
                        }
                    }
                }, 100);
            }

        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            this.menuActive()
        }
    }
</script>


<style src="@/assets/scss/_sidebar.scss" lang="scss" scoped />