<template>
    <div class="withdrawals" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">
                    {{ getMyLanguage("withdrawals","withdrawals.title") }}
                </h3>
                
                <div class="search-bar" v-if="dataWithdrawCommissions.length">
                    <input type="text" class="form form-search" id="search" v-on:keyup="filterList" :placeholder='getMyLanguage("backoffice","placeholder.search")' autocomplete="off">
                    <div class="btn-filter" @click="filter = true"></div>
                </div>

                <div class="load" v-if="!showContent"></div>


                <!-- BOX ERROR -->
                <div class="box-error" v-if="showContent && errorsGet.dataWithdrawCommissions != false">
                    <h3 class="title-secondary">{{ getMyLanguage("backoffice","title-error") }}</h3>
                    <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataWithdrawCommissions}}
                    </div>
                </div>
                
                <div class="box no-results" v-if="showContent && !dataWithdrawCommissions.length && !errorsGet.dataWithdrawCommissions">
                    {{ getMyLanguage("backoffice","no-data") }}
                </div>

                <div class="boxes" v-if="showContent && dataWithdrawCommissions.length && !errorsGet.dataWithdrawCommissions">
                    <div class="box">
                        <div class="scroll scroll-horizontal">

                            <div class="filter box" v-if="filterAgents">
                                <div class="close-filter" @click="filterAgents = false"></div>
                                
                                <div class="filters">
                                    <div class="form-group-switch">
                                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                                        <label for="stage" class="form-switch-label">
                                            <small>Filter name</small>
                                        </label>
                                    </div>
                                </div>

                                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
                            </div>

                            <table class="list-result">
                                <thead class="header-list">
                                    <tr>
                                        <th class="date">{{ getMyLanguage("list-result","requested") }}</th>
                                        <th class="fullName">{{ getMyLanguage("list-result","name") }}</th>
                                        <th class="destiny">{{ getMyLanguage("list-result","destiny") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","review") }}</th>
                                        <th class="status">{{ getMyLanguage("list-result","status") }}</th>
                                        <th class="date">{{ getMyLanguage("list-result","payUpTo") }}</th>
                                        <th class="value">{{ getMyLanguage("list-result","value") }}</th>
                                        <th class="currency">{{ getMyLanguage("list-result","currency") }}</th>
                                    </tr>
                                </thead>
                                
                                <tbody id="drop-list">
                                    <tr class="list" v-for="(commissionsList,index) in dataWithdrawCommissions" :key="index" @click="viewWithdraw(index)">
                                        <td class="date"><span v-html="dateTime(commissionsList.transaction.operations[0].created_at)"></span></td>
                                        <td class="fullName"><span>{{commissionsList.transaction.operations[0].account.full_name}}</span></td>
                                        
                                        <!-- DESTINY -->
                                        <td class="destiny"><span v-if="commissionsList.transaction.operations[0].destiny_wallet">{{commissionsList.transaction.operations[0].destiny_wallet.name}}</span></td>
                                        <!-- <td class="status tooltip" :class="commissionsList.transaction.operations[0].destiny_wallet.status_extra"> -->
                                        <td class="status tooltip" :class="addClassStatusDesiny(index)">
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet">{{ getMyLanguage("status", commissionsList.transaction.operations[0].destiny_wallet.status_extra) }}</span>
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet" class="tooltip">{{ getMyLanguage("destiny", "tooltip." + commissionsList.transaction.operations[0].destiny_wallet.status_extra) }}</span>
                                        </td>
                                        
                                        <!-- REVIEW -->
                                        <td class="status tooltip" :class="addClassStatusReview(index)">
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet">{{ getMyLanguage("status", commissionsList.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra) }}</span>
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet" class="tooltip">{{ getMyLanguage("review", "tooltip." + commissionsList.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra) }}</span>
                                        </td>

                                        <!-- STATUS -->
                                        <td class="status tooltip" :class="addClassStatusReview(index)">
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet">{{ getMyLanguage("status", commissionsList.transaction.operations[0].operation_status_extra) }}</span>
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet" class="tooltip">{{ getMyLanguage("withdrawals", "tooltip." + commissionsList.transaction.operations[0].operation_status_extra) }}</span>
                                        </td>
                                        
                                        <td class="date">
                                            <span v-if="commissionsList.transaction.operations[0].destiny_wallet" v-html="dateTime(commissionsList.transaction.operations[0].deadline)"></span>
                                        </td>

                                        <td>
                                            <span>{{(commissionsList.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:commissionsList.transaction.operations[0].asset.short})}}</span>
                                        </td>

                                        <td class="currency">
                                            <span>{{commissionsList.transaction.operations[0].asset.name}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- MODAL FILTER -->
        <div class="modal" v-if="filter">
            <div class="close-modal" @click="filter = false"></div>
            <div class="box cont-modal">
                <h3 v-if="filter">{{ getMyLanguage("backoffice","title.filter") }}</h3>
                <div class="filters">
                    <div class="form-group-switch">
                        <input type="checkbox" v-model="filtersCheck.stage" id="stage" class="form-switch">
                        <label for="stage" class="form-switch-label">
                            <small>Filter name</small>
                        </label>
                    </div>
                </div>

                <div class="btn-primary">{{ getMyLanguage("backoffice","btn.filter") }}</div>
            </div>
        </div>
        <div class="overlayModal" v-if="filter"></div>
            


        <!-- MODAL -->
        <div class="modal modal-withdraw" v-if="modalShow">
            <div @click="closeModal()" class="close-modal"></div>
            <div class="box cont-modal">
                <div class="scroll-vertical">

                    <div v-if="!errorsGet.dataWithdrawCommissions">

                        <!-- RESUME WITHDRAW -->
                        <h3 v-if='!flow.reject.status && !flow.accept.status && (flow.paymentConfirm.status != "approved" && flow.paymentConfirm.status != "paid" && flow.paymentConfirm.status != "cancelled" && flow.paymentConfirm.status != "toCancel")'>
                            {{getMyLanguage("withdrawals","withdrawals.solicitation.title")}}
                            <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                        </h3>
                        
                        <div class="resume" v-if='!flow.reject.status && !flow.accept.status && (flow.paymentConfirm.status != "approved" && flow.paymentConfirm.status != "paid" && flow.paymentConfirm.status != "cancelled" && flow.paymentConfirm.status != "toCancel")'>

                            <!-- DESTINY -->
                            <div class="box destiny" :class="dataModal.transaction.operations[0].destiny_wallet.status_extra">
                                <h4 class="box-title">{{getMyLanguage("withdrawals","withdrawals.box-title.destiny")}}</h4>

                                <div class="cont">
                                    <div class="destination">
                                        <p>{{getMyLanguage("withdrawals","withdrawals.destiny.destination-wallet")}}</p>
                                        <p><strong>{{dataModal.transaction.operations[0].destiny_wallet.name}}</strong></p>
                                        <p><span class="copy" @click="copyAddress(dataModal.transaction.operations[0].destiny_wallet.address)">{{dataModal.transaction.operations[0].destiny_wallet.address}}</span></p>
                                    </div>
                                </div>

                                <div class="actions">
                                    <a :href='VUE_APP_BACKOFFICE_API + "api/v1/platform/attachments/" + dataModal.transaction.operations[0].destiny_wallet.attachment_id' target="_blank" class="btn-action view" v-if='dataModal.transaction.operations[0].destiny_wallet.attachment_type != "none"'></a>

                                    <div class="btn-action reject disabled" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra === "Active"'></div>
                                    <div class="btn-action accept disabled" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra === "Active"'></div>

                                    <div class="btn-action reject" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra != "Active"' @click='openReject("destiny",dataModal.transaction.operations[0].destiny_wallet.status_reason)'></div>
                                    <div class="btn-action accept" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra != "Active"' @click='openAccept("destiny",dataModal.transaction.operations[0].destiny_wallet.status_reason)'></div>
                                </div>

                                <div class="reported">
                                    {{dataModal.transaction.operations[0].destiny_wallet.status_reason}}
                                </div>
                            </div>


                            <!-- REVIEW -->
                            <div class="box review" :class='dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra'>
                                <h4 class="box-title">{{getMyLanguage("withdrawals","withdrawals.box-title.review")}}</h4>

                                <div class="cont">
                                    <table>
                                        <tr>
                                            <td>{{getMyLanguage("withdrawals","withdrawals.review.requested-amount")}}</td>
                                            <td><small>({{dataModal.transaction.operations[0].asset.name}})</small> <strong>{{(dataModal.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:dataModal.transaction.operations[0].asset.short})}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>{{getMyLanguage("withdrawals","withdrawals.review.requested-in")}}</td>
                                            <td><strong>{{ dateTime(dataModal.transaction.operations[0].created_at) }}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>{{getMyLanguage("withdrawals","withdrawals.review.deadline")}}</td>
                                            <td><strong>{{ dateTime(dataModal.transaction.operations[0].deadline) }}</strong></td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="actions">
                                    <a :href='VUE_APP_BACKOFFICE_API + "api/v1/platform/attachments/" + dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id' target="_blank" class="btn-action view" v-if='dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].attachment_type != "none"'></a>

                                    <div class="btn-action reject disabled" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra != "Active" || dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra === "denied"'></div>
                                    <div class="btn-action accept disabled" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra != "Active" || dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra === "denied"'></div>
                                
                                    <div class="btn-action reject" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra === "Active" && dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra != "denied"' @click='openReject("withdraw",dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason)'></div>
                                    <div class="btn-action accept" v-if='dataModal.transaction.operations[0].destiny_wallet.status_extra === "Active" && dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra != "denied"' @click='openAccept("withdraw",dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason)'></div>
                                </div>

                                <div class="reported">
                                    {{dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason}}
                                    <!-- <span>{{dataModal.transaction.operations[0].reviews[1].operation_review_status_reason}}</span> -->
                                </div>
                            </div>
                        </div>


                        <!-- REJECT-->
                        <div class="reject show" v-if="flow.reject.status">
                            <h3>
                                <span v-if='flow.reject.type === "destiny"'>{{getMyLanguage("withdrawals","action.denied.destiny")}}</span>
                                <span v-if='flow.reject.type === "withdraw"'>{{getMyLanguage("withdrawals","action.denied.withdraw")}}</span>
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <p v-if='flow.reject.type === "destiny"' class="question"><strong>{{getMyLanguage("withdrawals","question.denied.destiny")}}</strong></p>
                            <p v-if='flow.reject.type === "withdraw"' class="question"><strong>{{getMyLanguage("withdrawals","question.denied.withdraw")}}</strong></p>
                            
                            <div class="options">
                                <div v-if='flow.reject.type === "destiny"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.destiny.01")'>{{getMyLanguage("withdrawals","observation.reject.destiny.01")}}</div>
                                <div v-if='flow.reject.type === "destiny"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.destiny.02")'>{{getMyLanguage("withdrawals","observation.reject.destiny.02")}}</div>
                                <div v-if='flow.reject.type === "destiny"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.destiny.03")'>{{getMyLanguage("withdrawals","observation.reject.destiny.03")}}</div>
                                
                                <div v-if='flow.reject.type === "withdraw"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.withdraw.01")'>{{getMyLanguage("withdrawals","observation.reject.withdraw.01")}}</div>
                                <div v-if='flow.reject.type === "withdraw"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.withdraw.02")'>{{getMyLanguage("withdrawals","observation.reject.withdraw.02")}}</div>
                                <div v-if='flow.reject.type === "withdraw"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.reject.withdraw.03")'>{{getMyLanguage("withdrawals","observation.reject.withdraw.03")}}</div>
                            </div>

                            <div class="report" v-if="!flow.load">
                                <small>{{flow.observations.length}}/50</small>
                                <textarea class="form" :class="{formPending: flow.observations.length < 5}" v-model="flow.observations" maxlength="50"></textarea>
                            </div>

                            <div class="actions" v-if="!flow.load">
                                <div class="btn-primary btn-cancel" @click='flow.reject.status = false, flow.observations = ""'>{{getMyLanguage("backoffice","btn-no")}}</div>

                                <div class="btn-primary btn-disable" v-if="flow.observations.length < 5">{{getMyLanguage("backoffice","btn-yes")}}</div>
                                
                                <div class="btn-primary" v-if='flow.reject.type === "destiny" && flow.observations.length >= 5' @click='updateStatus("Inactive",flow.reject.type,flow.observations)'>{{getMyLanguage("backoffice","btn-yes")}}</div>
                                <div class="btn-primary" v-if='flow.reject.type === "withdraw" && flow.observations.length >= 5' @click='updateStatus("denied",flow.reject.type,flow.observations)'>{{getMyLanguage("backoffice","btn-yes")}}</div>
                            </div>

                            <div class="load" v-if="flow.load"></div>
                        </div>


                        <!-- APPROVE -->
                        <div class="approve show" v-if="flow.accept.status">
                            <h3>
                                <span v-if='flow.accept.type === "destiny"'>{{getMyLanguage("withdrawals","action.approve.destiny")}}</span>
                                <span v-if='flow.accept.type === "withdraw"'>{{getMyLanguage("withdrawals","action.approve.withdraw")}}</span>
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <p v-if='flow.accept.type === "destiny"' class="question"><strong>{{getMyLanguage("withdrawals","question.approve.destiny")}}</strong></p>
                            <p v-if='flow.accept.type === "withdraw"' class="question"><strong>{{getMyLanguage("withdrawals","question.approve.withdraw")}}</strong></p>

                            <div class="options">
                                <div v-if='flow.accept.type === "destiny"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.approve.destiny.01")'>{{getMyLanguage("withdrawals","observation.approve.destiny.01")}}</div>
                                <div v-if='flow.accept.type === "destiny"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.approve.destiny.02")'>{{getMyLanguage("withdrawals","observation.approve.destiny.02")}}</div>
                                
                                <div v-if='flow.accept.type === "withdraw"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.approve.withdraw.01")'>{{getMyLanguage("withdrawals","observation.approve.withdraw.01")}}</div>
                                <div v-if='flow.accept.type === "withdraw"' class="option" @click='flow.observations = getMyLanguage("withdrawals","observation.approve.withdraw.02")'>{{getMyLanguage("withdrawals","observation.approve.withdraw.02")}}</div>
                            </div>

                            <div class="report" v-if="!flow.load">
                                <small>{{flow.observations.length}}/50</small>
                                <textarea class="form" :class="{formPending: flow.observations.length < 5}" v-model="flow.observations" maxlength="50"></textarea>
                            </div>

                            <div class="actions" v-if="!flow.load">
                                <div class="btn-primary btn-cancel" @click='flow.accept.status = false, flow.observations = ""'>{{getMyLanguage("backoffice","btn-no")}}</div>

                                <div class="btn-primary btn-disable" v-if="flow.observations.length < 5">{{getMyLanguage("backoffice","btn-yes")}}</div>
                                
                                <div class="btn-primary" v-if='flow.accept.type === "destiny" && flow.observations.length >= 5' @click='updateStatus("Active",flow.accept.type,flow.observations)'>{{getMyLanguage("backoffice","btn-yes")}}</div>
                                <div class="btn-primary" v-if='flow.accept.type === "withdraw" && flow.observations.length >= 5' @click='updateStatus("approved",flow.accept.type,flow.observations)'>{{getMyLanguage("backoffice","btn-yes")}}</div>
                            </div>

                            <div class="load" v-if="flow.load"></div>
                        </div>


                        <!-- REGISTER PAYMENT -->
                        <div class="register-payment show" v-if='flow.paymentConfirm.status === "approved"'>
                            <h3>
                                {{getMyLanguage("withdrawals","register.payment.title")}}
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <div class="box" v-if="!flow.load">
                                <table>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","destiny") }}</td>
                                        <td>{{dataModal.transaction.operations[0].destiny_wallet.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN/SWIFT</td>
                                        <td>{{dataModal.transaction.operations[0].destiny_wallet.address}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","value") }}</td>
                                        <td>{{(dataModal.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:dataModal.transaction.operations[0].asset.short})}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","requested") }}</td>
                                        <td>{{ dateTime(dataModal.transaction.operations[0].created_at) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","paidIn") }}</td>
                                        <td><input type="date" class="form" :class="{formPending: flow.paymentConfirm.paymentDate.length < 5}" v-model="flow.paymentConfirm.paymentDate"></td>
                                    </tr>
                                </table>
                            </div>

                            <div class="report" v-if="!flow.load">
                                <small>{{flow.paymentConfirm.obsPayment.length}}/50</small>
                                <textarea class="form" :class="{formPending: flow.paymentConfirm.obsPayment.length < 5}" v-model="flow.paymentConfirm.obsPayment" maxlength="50"></textarea>
                            </div>
                            
                            <label for="receipt" v-if="!flow.load" class="btn-upload" :class="{fileSelected: flow.paymentConfirm.receipt != null}">
                                <span class="send">{{getMyLanguage("withdrawals","attach-receipt")}}</span>
                                <span class="selected">{{getMyLanguage("withdrawals","selected-file")}}</span>
                                <input type="file" accept="image/*" id="receipt" ref="file" @change="uploadFileWithdraw">
                            </label>

                            <div class="actions" v-if="!flow.load">
                                <div class="btn-primary btn-disable" v-if="flow.paymentConfirm.receipt == null || flow.paymentConfirm.paymentDate.length < 5 || flow.paymentConfirm.obsPayment.length < 5">{{getMyLanguage("backoffice","btn-confirm")}}</div>
                                <div class="btn-primary" v-if="flow.paymentConfirm.receipt != null && flow.paymentConfirm.paymentDate.length >= 5 && flow.paymentConfirm.obsPayment.length >= 5" @click='registerPayment()'>{{getMyLanguage("backoffice","btn-confirm")}}</div>
                                
                                <div class="btn-primary btn-cancel" @click='flow.paymentConfirm.status = "toCancel"'>{{getMyLanguage("backoffice","btn-cancel-payment")}}</div>
                            </div>

                            <div class="load" v-if="flow.load"></div>
                        </div>
                        
                        
                        
                        <!-- CANCEL PAYMENT -->
                        <div class="register-payment cancel-payment show" v-if='flow.paymentConfirm.status === "toCancel"'>
                            <h3>
                                {{getMyLanguage("withdrawals","cancel.payment.title")}}
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <div class="box" v-if="!flow.load">
                                <table>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","destiny") }}</td>
                                        <td>{{dataModal.transaction.operations[0].destiny_wallet.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>IBAN/SWIFT</td>
                                        <td>{{dataModal.transaction.operations[0].destiny_wallet.address}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","value") }}</td>
                                        <td>{{(dataModal.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:dataModal.transaction.operations[0].asset.short})}}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","requested") }}</td>
                                        <td>{{ dateTime(dataModal.transaction.operations[0].created_at) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ getMyLanguage("list-result","cancelledOn") }}</td>
                                        <td><input type="date" class="form" :class="{formPending: flow.paymentConfirm.paymentDate.length < 5}" v-model="flow.paymentConfirm.paymentDate"></td>
                                    </tr>
                                </table>
                            </div>

                            <div class="report" v-if="!flow.load">
                                <small>{{flow.paymentConfirm.obsPayment.length}}/50</small>
                                <textarea class="form" :class="{formPending: flow.paymentConfirm.obsPayment.length < 5}" v-model="flow.paymentConfirm.obsPayment" maxlength="50"></textarea>
                            </div>

                            <div v-if="!flow.load">
                                <div class="btn-primary btn-disable" v-if="flow.paymentConfirm.paymentDate.length < 5 || flow.paymentConfirm.obsPayment.length < 5">{{getMyLanguage("backoffice","btn-confirm")}}</div>
                                <div class="btn-primary" v-if="flow.paymentConfirm.paymentDate.length >= 5 && flow.paymentConfirm.obsPayment.length >= 5" @click='cancelPayment()'>{{getMyLanguage("backoffice","btn-confirm")}}</div>
                            </div>

                            <div class="load" v-if="flow.load"></div>
                        </div>


                        <!-- PAID -->
                        <div class="registered-payment" v-if='flow.paymentConfirm.status === "paid"'>
                            <h3>
                                {{getMyLanguage("withdrawals","registered-payment")}}
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <div class="box">
                                <div class="destiny">
                                    <h4 class="subtitle">{{getMyLanguage("withdrawals","registered-payment.subtitle.destiny")}}</h4>
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","destiny") }}</td>
                                            <td>{{dataModal.transaction.operations[0].destiny_wallet.name}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","address") }}</td>
                                            <td>{{dataModal.transaction.operations[0].destiny_wallet.address}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].destiny_wallet.status_reason}}</small></td>
                                        </tr>
                                        <tr v-if='dataModal.transaction.operations[0].destiny_wallet.attachment_type != "none"'>
                                            <td colspan="2">
                                                <a :href='VUE_APP_BACKOFFICE_API + "api/v1/platform/attachments/" + dataModal.transaction.operations[0].destiny_wallet.attachment_id' target="_blank" class="btn-primary btn-view"><span>{{getMyLanguage("withdrawals","view-receipt2")}}</span></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="review">
                                    <h4 class="subtitle">{{getMyLanguage("withdrawals","registered-payment.subtitle.review")}}</h4>
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","value") }}</td>
                                            <td>{{(dataModal.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:dataModal.transaction.operations[0].asset.short})}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","requested") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].created_at) }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("withdrawals","withdrawals.review.deadline") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].deadline) }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason}}</small></td>
                                        </tr>
                                        <tr v-if='dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].attachment_type != "none"'>
                                            <td colspan="2">
                                                <a :href='VUE_APP_BACKOFFICE_API + "api/v1/platform/attachments/" + dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id' target="_blank" class="btn-primary btn-view"><span>{{getMyLanguage("withdrawals","view-receipt")}}</span></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="registered">
                                    <h4 class="subtitle">{{getMyLanguage("withdrawals","registered-payment.subtitle.payment")}}</h4>
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","paidIn") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].operation_effective_date) }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].operation_status_reason}}</small></td>
                                        </tr>
                                        <tr v-if='dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "deposit_receipt")[0].attachment_type != "none"'>
                                            <td colspan="2">
                                                <a :href='VUE_APP_BACKOFFICE_API + "api/v1/platform/attachments/" + dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "deposit_receipt")[0].operation_review_id' target="_blank" class="btn-primary btn-view"><span>{{getMyLanguage("withdrawals","view-receipt")}}</span></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                        
                        
                        <!-- CANCELLED -->
                        <div class="cancelled-payment" v-if='flow.paymentConfirm.status === "cancelled"'>
                            <h3>
                                {{getMyLanguage("withdrawals","cancelled-payment")}}
                                <small>{{dataModal.transaction.operations[0].account.full_name}}</small>
                            </h3>

                            <div class="box">
                                <div class="destiny">
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","destiny") }}</td>
                                            <td>{{dataModal.transaction.operations[0].destiny_wallet.name}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","address") }}</td>
                                            <td>{{dataModal.transaction.operations[0].destiny_wallet.address}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].destiny_wallet.status_reason}}</small></td>
                                        </tr>
                                    </table>
                                </div>

                                <!-- <div class="review">
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","value") }}</td>
                                            <td>{{(dataModal.transaction.operations[0].quantity * -1).toLocaleString("pt-PT", {style:"currency", currency:dataModal.transaction.operations[0].asset.short})}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","requested") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].created_at) }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ getMyLanguage("withdrawals","withdrawals.review.deadline") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].deadline) }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason}}</small></td>
                                        </tr>
                                    </table>
                                </div> -->

                                <div class="registered">
                                    <table>
                                        <tr>
                                            <td>{{ getMyLanguage("list-result","cancelledOn") }}</td>
                                            <td>{{ dateTime(dataModal.transaction.operations[0].operation_effective_date) }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><small>{{dataModal.transaction.operations[0].operation_status_reason}}</small></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>



                    <!-- ERROR -->
                    <div class="box-error show" v-if="errorsPost.dataWithdrawCommissions != false">
                        <h3 class="title-secondary">{{ getMyLanguage("backoffice","title-error") }}</h3>
                        <p>{{ getMyLanguage("backoffice","has-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errorsPost.dataWithdrawCommissions}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modalShow"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import axios from 'axios';
    import moment from 'moment';
    import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        
        data () {
            return {
                VUE_APP_BACKOFFICE_API: process.env.VUE_APP_BACKOFFICE_API,
                flagSelected: {},
                showContent: false,

                dataWithdrawCommissions: [],
                dataModal: [],

                flow: {
                    reject: {
                        status: false,
                        type: "",
                    },
                    accept: {
                        status: false,
                        type: "",
                    },
                    load: false,
                    observations: "",
                    paymentConfirm: {
                        status: false,
                        paymentDate: "",
                        receipt: null,
                        obsPayment: ""
                    }
                },

                filter: false,
                filtersParam: {},
                filtersCheck: {},
                filterAgents: false,
                modalShow: false,

                errorsGet: {
                    dataWithdrawCommissions: false
                },
                errorsPost: {
                    dataWithdrawCommissions: false
                },
            }
        },

        async mounted () {            
            await apiBackoffice.get('/api/v1/pay/transactions/withdrawals')
            .then(response => {
                this.dataWithdrawCommissions = response.data
            })
            .catch(error => {
                this.errorsGet.dataWithdrawCommissions = error.response.status
            })
            
            setTimeout(() => {
                this.showContent = true
            }, 1000);
        },

        methods: {
            closeModal(){
                this.flow.reject.status = false
                this.flow.accept.status = false
                this.flow.observations = ""
                this.flow.paymentConfirm.status = ""
                this.flow.paymentConfirm.paymentDate = ""
                this.flow.paymentConfirm.obsPayment = ""
                this.flow.paymentConfirm.receipt = null
                this.modalShow = false
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },

            addClassStatusDesiny(value){
                const classes = [];
                if (this.dataWithdrawCommissions[value].transaction.operations[0].destiny_wallet) {
                    classes.push(this.dataWithdrawCommissions[value].transaction.operations[0].destiny_wallet.status_extra);
                }
                return classes;
            },
            
            addClassStatusReview(value){
                const classes = [];
                if (this.dataWithdrawCommissions[value].transaction.operations[0].destiny_wallet) {
                    classes.push(this.dataWithdrawCommissions[value].transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra);
                }
                return classes;
            },
            
            addClassStatus(value){
                const classes = [];
                if (this.dataWithdrawCommissions[value].transaction.operations[0].destiny_wallet) {
                    classes.push(this.dataWithdrawCommissions[value].transaction.operations[0].operation_status_extra);
                }
                return classes;
            },

            filterList() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("search");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("tr");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("td")[1];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            viewWithdraw(value){
                if (this.dataWithdrawCommissions[value].transaction.operations[0].destiny_wallet) {
                    this.dataModal = this.dataWithdrawCommissions[value]
                    this.dataModal.index = value
    
                    this.flow.paymentConfirm.status = this.dataModal.transaction.operations[0].operation_status_extra
                    
                    this.modalShow = true
                } else {
                    this.$toast.error(this.getMyLanguage("backoffice","has-error"));
                }
            },

            async copyAddress(address) {
                try {
                await navigator.clipboard.writeText(address);
                    this.$toast.success(this.getMyLanguage("copy","copied"));
                    setTimeout(this.$toast.clear, 3000)
                }
                
                catch($e) {
                    this.$toast.success(this.getMyLanguage("copy","nocopied"));
                    setTimeout(this.$toast.clear, 3000)
                }
            },

            openReject(type,observations){
                this.flow.reject.status = true
                this.flow.reject.type = type
                this.flow.observations = observations

                if ( this.flow.observations === null ){
                    this.flow.observations = ""
                }
            },
            
            openAccept(type,observations){
                this.flow.accept.status = true
                this.flow.accept.type = type
                this.flow.observations = observations

                if ( this.flow.observations === null ){
                    this.flow.observations = ""
                }
            },

            updateStatus(status,type,reason){
                this.flow.load = true
                
                // DESTINY
                if ( type === "destiny" ){
                    apiBackoffice.post('/api/v1/pay/transactions/wallet/status', {"account_wallet_id": this.dataModal.transaction.operations[0].destiny_wallet.account_wallet_id,"account_wallet_status": status,"account_wallet_status_reason": reason})
                    .then(response => {
                        console.log(response)
    
                        setTimeout(() => {
                            this.dataModal.transaction.operations[0].destiny_wallet.status_extra = response.data.account_wallet_status
                            this.dataModal.transaction.operations[0].destiny_wallet.status_reason = response.data.account_wallet_status_reason

                            if ( status === "Inactive" ){
                                this.dataWithdrawCommissions[this.dataModal.index].transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra = "denied"
                                this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra = "denied"
                                this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason = "Destiny error"
                            }
                            

                            this.dataModal.transaction.operations[0].destiny_wallet.status_extra = status
                            this.dataModal.transaction.operations[0].destiny_wallet.status_reason = reason

                            if ( status === "Inactive" ){
                                this.dataWithdrawCommissions[this.dataModal.index].transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra = "denied"
                                this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_extra = "denied"
                                this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_status_reason = "Destiny error"
                            }
    
                            this.flow.accept.status = false
                            this.flow.reject.status = false

                            this.flow.load = false
                        }, 300);

                        this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                        setTimeout(this.$toast.clear, 6000)
                    })
                    .catch(error => {
                        setTimeout(() => {
                            this.flow.load = false
                        }, 300);

                        this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                        setTimeout(this.$toast.clear, 6000)
                        console.log(error)

                        // temp
                        this.dataModal.transaction.operations[0].destiny_wallet.status_extra = status
                        this.dataModal.transaction.operations[0].destiny_wallet.status_reason = reason
                    })
                }
                
                
                // WITHDRAW
                if ( type === "withdraw" ){
                    apiBackoffice.post('/api/v1/pay/transactions/operation/' + this.dataModal.transaction.operations[0].operation_id + '/review', {"operation_review_id": this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id,"operation_review_status": status,"operation_review_status_reason": reason})
                    .then(response => {
                        console.log(response)

                        this.dataModal.transaction = response.data.transaction
                        
                        setTimeout(() => {
                            // UPDATES FIXED
                            // this.flow.paymentConfirm.status = status                            
                            this.flow.paymentConfirm.status = this.dataModal.transaction.operations[0].operation_status_extra
                            this.flow.load = false
                            this.flow.accept.status = false
                            this.flow.reject.status = false
                        }, 300);
                        
                        this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                        setTimeout(this.$toast.clear, 6000)

                    })
                    .catch(error => {
                        setTimeout(() => {
                            this.flow.load = false
                        }, 300);
                        
                        this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                        setTimeout(this.$toast.clear, 6000)
                        console.log(error)
                        
                        // this.flow.paymentConfirm.status = status
                    })
                }


                // RESET
                this.flow.observations = ""
            },


            // REGISTER PAYMENT
            uploadFileWithdraw() {
                this.flow.paymentConfirm.receipt = this.$refs.file.files[0];
                console.log(this.flow.paymentConfirm.receipt)
            },

            registerPayment(){
                this.flow.load = true

                const formData = new FormData();
                formData.append('file', this.flow.paymentConfirm.receipt);
                formData.append('operation_id', this.dataModal.transaction.operations[0].operation_id);
                formData.append('operation_review_id', this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id);
                formData.append('operation_status', "paid");
                formData.append('operation_status_reason', this.flow.paymentConfirm.obsPayment);
                formData.append('operation_effective_date', this.flow.paymentConfirm.paymentDate);
                // formData.append('operation_effective_date', "2022-12-07T00:00:00Z");

                // console.log('file' + this.flow.paymentConfirm.receipt);
                // console.log('operation_id' + this.dataModal.transaction.operations[0].operation_id);
                // console.log('operation_review_id' + this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id);
                // console.log('operation_status' + "paid");
                // console.log('operation_status_reason' + this.flow.paymentConfirm.obsPayment);
                // console.log('operation_effective_date' + this.flow.paymentConfirm.paymentDate);
                
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(this.VUE_APP_BACKOFFICE_API + 'api/v1/pay/transactions/operation/status', formData, { headers })
                .then(response => {
                    console.log(response)

                    this.dataModal.transaction = response.data.transaction

                    setTimeout(() => {
                        this.flow.paymentConfirm.status = "paid"
                        this.flow.load = false
                    }, 300);
                    
                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                    setTimeout(this.$toast.clear, 6000)
                })
                .catch(error => {
                    this.errorsPost.withdraw = error.response.status
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                    setTimeout(this.$toast.clear, 6000)
                })
            },            
            
            cancelPayment(){
                this.flow.load = true

                const formData = new FormData();
                formData.append('operation_id', this.dataModal.transaction.operations[0].operation_id);
                formData.append('operation_review_id', this.dataModal.transaction.operations[0].reviews.filter(or => or.operation_review_type_code === "withdraw_receipt")[0].operation_review_id);
                formData.append('operation_status', "cancelled");
                formData.append('operation_status_reason', this.flow.paymentConfirm.obsPayment);
                formData.append('operation_effective_date', this.flow.paymentConfirm.paymentDate);
                // formData.append('operation_effective_date', "2022-12-07T00:00:00Z");
                
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(this.VUE_APP_BACKOFFICE_API + 'api/v1/pay/transactions/operation/status', formData, { headers })
                .then(response => {
                    console.log(response)

                    this.dataModal.transaction = response.data.transaction

                    setTimeout(() => {
                        this.flow.paymentConfirm.status = "cancelled"
                        this.flow.load = false
                    }, 300);
                    
                    this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveDataSuccess"));
                    setTimeout(this.$toast.clear, 6000)
                })
                .catch(error => {
                    this.errorsPost.withdraw = error.response.status
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.saveDataError"));
                    setTimeout(this.$toast.clear, 6000)
                })
            },

            // UPDATE LIST
            async updateList(){
                const updateWithdrawals = await apiBackoffice.get('/api/v1/pay/transactions/withdrawals')
                this.dataWithdrawCommissions = updateWithdrawals.data
                this.dataModal = this.dataWithdrawCommissions[this.dataModal.index]
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-withdrawals.scss" lang="scss" scoped />