<template>
<div class="main">
    <router-view/>
</div>

</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import apiBackoffice from '@/config/apiBackoffice.js'
    import apiPlatform from '@/config/apiPlatform.js'

    export default {
        data: function () {
            return {
                language: 'en',
                // group: 'dne-platform',
                dataAccount: [],
                dataPhaseCurrent: [],
                flagSelected: 'en',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',
                statusLogin: {
                    logged: false
                }
            }
        },

        beforeMount(){
            this.loginStatus();
            this.flagUrl()
        },

        async mounted(){
            // LANGUAGES
            const resumeLanguage = await apiPlatform.get('api/v1/platform/language/dne_backoffice')
            this.dataLanguage = resumeLanguage.data
            this.$store.commit('allLanguages',this.dataLanguage)

            this.flagImg = this.dataLanguage.filter(lang => lang.code === this.flagSelected)[0].flag
            this.$store.commit('preLanguageFlag',this.flagImg)

            // COUNTRIES
            const resumeCountries = await apiPlatform.get('api/v1/platform/country')
            this.dataCountries = resumeCountries.data
            this.$store.commit("allCountries",this.dataCountries)
        },

        methods: {
            loginStatus(){
                const tokenValue = localStorage.getItem('accessToken')

                if ( tokenValue == undefined || tokenValue == null ){
                    this.statusLogin.logged = false
                    this.$store.commit('loginStatus', false)
                } else {
                    apiBackoffice.get('/api/v1/account/profile/')
                    .then(response => {
                        this.$store.commit('profile',response.data)
                        this.$store.commit('loginStatus', true)
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        let errorsLogin = JSON.stringify(error.response.status)
                        this.loginErrors = errorsLogin

                        if ( errorsLogin == 401 || errorsLogin == 403 ){
                            localStorage.removeItem('accessToken');
                            this.statusLogin.logged === false
                            this.statusLogin.logged = false
                            this.$store.commit('loginStatus', false)
                            
                            window.location.href = "/"
                        }
                    });
                }

            },


            // loginStatus(){
            //     const tokenValue = localStorage.getItem('accessToken')

            //     if ( tokenValue == undefined || tokenValue == null ){
            //         this.statusLogin.logged = false
            //         this.$store.commit('loginStatus', false)
            //     } else {
            //         apiBackoffice.get('/api/v1/platform/account/person/')
            //         .then(response => {
            //             this.$store.commit('dataUser',response.data)
            //             this.$store.commit('loginStatus', true)
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //             let errorsLogin = JSON.stringify(error.response.status)
            //             this.loginErrors = errorsLogin

            //             if ( errorsLogin == 401 || errorsLogin == 403 ){
            //                 localStorage.removeItem('accessToken');
            //                 this.statusLogin.logged === false
            //                 this.statusLogin.logged = false
            //                 this.$store.commit('loginStatus', false)
                            
            //                 window.location.href = "/"
            //             }
            //         });
            //     }

            // },

            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
                let language = "English"

                if ( !code ) {
                    code = 'en',
                    this.$store.commit('preLanguageFlag',flag)
                    localStorage.setItem('codeLanguage',code);
                    localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
                } else{
                    this.flagSelected = code
                    this.$store.commit('preLanguage',code)
                    this.$store.commit('preLanguageName',language)
                }

                if (this.flagSelected === "ar"){
                    document.body.classList.add('ar');
                }
            },

            // flagUrl(){
            //     let code = String(window.location.pathname).split("/")[1];
            //     let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
            //     let language = "English"
            //     if (!code) {
            //         code = 'en',
            //         this.$store.commit('preLanguageFlag',flag)
            //         localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
            //     } else{
            //         this.flagSelected = code
            //         this.$store.commit('preLanguage',code)
            //         this.$store.commit('preLanguageName',language)
            //     }

            //     if (this.flagSelected === "ar"){
            //         document.body.classList.add('ar');
            //     }
            // },

            changeLanguage(parLanguage){
                this.language = parLanguage;
                let url = String(window.location.pathname).split("/");
                
                url[1] = parLanguage;
                window.location.href = `${url.join('/')}`;
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />