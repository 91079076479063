import en from '@/locales/en.json';
import es from '@/locales/es.json';
import pt from '@/locales/pt.json';

export default function getMyFavouriteLanguage (group, code, storeLanguage){

    let lang = [];
    
    if(storeLanguage === "en"){
        lang = en;
    }
    else if(storeLanguage === "es"){
        lang = es;
    }
    else if(storeLanguage === "pt"){
        lang = pt;
    }
    else{
        lang = en;
    }

    return lang.filter((item) => item.group === group).filter((item) => item.code === code).map(item => item.value).join();
}