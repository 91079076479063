<template>
    <div class="dashboard">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <h3 class="title-secondary">{{getMyLanguage("dashboard","dashboard.title")}}</h3>

                <div class="load" v-if="!showContent"></div>

                <!-- CARDS -->
                <div class="cards">

                    <!-- WITHDRAW COMMISSION -->
                    <div class="box">
                        <h3>Solicitações de levantamento</h3>
                        
                        <div class="card">
                            <div class="header-card"><strong>Comissões</strong></div>

                            <div class="cont-card">
                                <div class="topic-card new">
                                    <p>Novo</p>
                                    <p><strong>2</strong></p>
                                </div>
                                <div class="topic-card payd">
                                    <p>Pago</p>
                                    <p><strong>12</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- PAYMENTS -->
                    <div class="box">
                        <h3>Pagamentos para aprovação</h3>
                        
                        <div class="card">
                            <div class="header-card"><strong>Vendas</strong></div>

                            <div class="cont-card">
                                <div class="topic-card new">
                                    <p>Novo</p>
                                    <p><strong>2</strong></p>
                                </div>
                                <div class="topic-card payd">
                                    <p>Aprovados</p>
                                    <p><strong>12</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- SUPPORT REGISTER -->
                    <div class="box">
                        <h3>Registos para revisão</h3>
                        
                        <div class="card">
                            <div class="header-card"><strong>Suporte</strong></div>

                            <div class="cont-card">
                                <div class="topic-card new">
                                    <p>Novo</p>
                                    <p><strong>2</strong></p>
                                </div>
                                <div class="topic-card payd">
                                    <p>Ajustados</p>
                                    <p><strong>12</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- DOCUMENTS -->
                    <div class="box">
                        <h3>Revisão de documentos</h3>
                        
                        <div class="card">
                            <div class="header-card"><strong>Suporte</strong></div>

                            <div class="cont-card">
                                <div class="topic-card new">
                                    <p>Novo</p>
                                    <p><strong>2</strong></p>
                                </div>
                                <div class="topic-card payd">
                                    <p>Ajustados</p>
                                    <p><strong>12</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Chart -->
                <div class="charts" v-if="showContent">
                    <div class="box box-6 Chart">
                        <Chart :options="dataChart" />
                    </div>
                    <div class="box box-6 Chart">
                        <Chart :options="dataChart" />
                    </div>
                    <div class="box box-12 Chart">
                        <Chart :options="dataChart" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    import Sidebar from '@/components/Sidebar.vue';

    // import apiBackoffice from '@/config/apiBackoffice.js'
    import moment from 'moment';

    import { Chart } from 'highcharts-vue'

    export default {
        components: {
            Sidebar,
            Chart
        },

        data () {
            return {
                flagSelected: {},
                showContent: false,
                dataDash: {},
                
                modalWithdraw: {
                    modal: false,
                    select: true,
                    finish: false,
                    success: false,
                    unavailable: false
                },
                
                acceptWithdraw: false,
                withdrawLoad: false,
                successWithdraw: false,

                dataChart: {}
            }
        },

        async mounted () {
            // let resumeDash = ''

            // if ( this.$route.params.registerId === undefined ){
            //     resumeDash = await apiBackoffice.get('/api/v1/reports/commissions/agent/dashboard')
            // } else{
            //     resumeDash = await apiBackoffice.get('/api/v1/reports/commissions/agent/dashboard/' + this.$route.params.registerId)
            // }

            // this.dataDash = resumeDash.data
            
            // const resumeChart = await apiBackoffice.get('/api/v1/reports/commissions/agent/weekly')
            // this.dataChart = resumeChart.data

            setTimeout(() => {
                this.showContent = true
            }, 1500);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).locale(this.flagSelected).format('LL');
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>


<style src="./custom-dashboard.scss" lang="scss" scoped />