<template>
    <div class="agents" @keydown.esc="this.modalShow = false" tabindex="0">
        <Sidebar />

        <div class="content-backoffice">
            <div class="content">
                
                <!-- CONTENT -->
                <h3 class="title-secondary">{{ getMyLanguage("transactions", "transactions.title") }}</h3>
               
                <div class="selects-filter">

                    <!-- FILTER DATE -->
                    <div class="select">
                        <div class="option-selected" :class='{selected: filter.selectedDate.val != ""}' @click="optionsDate(true)" tabindex="0" @blur="optionsDate(false)">{{ getMyLanguage("transactions","filterDate." + filter.selectedDate.lbl) }}</div>

                        <div class="options" :class="{closing: filter.closingDate}" v-if="filter.optionsDate">
                            <div class="option" :class="{selected: filterListDate.val === filter.selectedDate.val}" v-for="(filterListDate, index) in filter.filterDate" :key="index" @click="filter.selectedDate.lbl = filterListDate.lbl, filter.selectedDate.val = filterListDate.val, optionsDate(false)">{{ getMyLanguage("transactions","filterDate." + filterListDate.lbl) }}</div>
                        </div>
                    </div>
                    
                    <!-- FILTER TRANSACTIONS -->
                    <div class="select">
                        <div class="option-selected" :class='{selected: filter.selectedTransaction.val != ""}' @click="optionsTransactions(true)" tabindex="0" @blur="optionsTransactions(false)">{{ getMyLanguage("transactions","filterTransaction." + filter.selectedTransaction.lbl) }}</div>

                        <div class="options" :class="{closing: filter.closingTransactions}" v-if="filter.optionsTransactions">
                            <div class="option" :class="{selected: filterTransaction.val === filter.selectedTransaction.val}" v-for="(filterTransaction, index) in filter.filterTransaction" :key="index" @click="filter.selectedTransaction.lbl = filterTransaction.lbl, filter.selectedTransaction.val = filterTransaction.val, optionsTransactions(false)">{{ getMyLanguage("transactions","filterTransaction." + filterTransaction.lbl) }}</div>
                        </div>
                    </div>
                    
                    <!-- FILTER STATUS -->
                    <div class="select">
                        <div class="option-selected" :class='{selected: filter.selectedStatus.val != ""}' @click="optionsStatus(true)" tabindex="0" @blur="optionsStatus(false)">{{ getMyLanguage("transactions","filterStatus." + filter.selectedStatus.lbl) }}</div>

                        <div class="options" :class="{closing: filter.closingStatus}" v-if="filter.optionsStatus">
                            <div class="option" :class="{selected: filterStatus.val === filter.selectedStatus.val}" v-for="(filterStatus, index) in filter.filterStatus" :key="index" @click="filter.selectedStatus.lbl = filterStatus.lbl, filter.selectedStatus.val = filterStatus.val, optionsStatus(false)">{{ getMyLanguage("transactions","filterStatus." + filterStatus.lbl) }}</div>
                        </div>
                    </div>
                    
                    <!-- FILTER CURRENCY -->
                    <div class="select">
                        <div class="option-selected" :class='{selected: filter.selectedCurrency.val != ""}' @click="optionsCurrency(true)" tabindex="0" @blur="optionsCurrency(false)">{{ getMyLanguage("transactions","filterCurrency." + filter.selectedCurrency.lbl) }}</div>

                        <div class="options" :class="{closing: filter.closingCurrency}" v-if="filter.optionsCurrency">
                            <div class="option" :class="{selected: filterCurrency.val === filter.selectedCurrency.val}" v-for="(filterCurrency, index) in filter.filterCurrency" :key="index" @click="filter.selectedCurrency.lbl = filterCurrency.lbl, filter.selectedCurrency.val = filterCurrency.val, optionsCurrency(false)">{{ getMyLanguage("transactions","filterCurrency." + filterCurrency.lbl) }}</div>
                        </div>
                    </div>

                    <div class="btn-primary btn-filter">{{ getMyLanguage("transactions","btn.filter") }}</div>
                </div>

            </div>
        </div>
            


        <!-- MODAL -->
        <div class="modal" v-if="this.modalShow === true">
            <div @click="(this.modalShow = false),(cancel())" class="close-modal"></div>
            <div class="box cont-modal phase-modal">
                <div class="scroll-vertical">
                    <h3 v-if="flowModal.resume && flowModal.actions">{{dataModal.name}}</h3>

                    <div class="resume-box">

                        <!-- RESUME -->
                        <div class="alert" v-if='dataModal.status != "Activated"'>O cliente ainda não concluiu todas as etapas de registo.</div>
                        <div class="box resume" :class="dataModal.status" v-if="flowModal.resume">
                            <p><strong>{{ getMyLanguage("list-result","date") }}: </strong>{{dataModal.createdAt}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{ getMyLanguage("list-result", dataModal.agentType) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.regionName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","language") }}: </strong>{{dataModal.language}}</p>
                            <p><strong>{{ getMyLanguage("list-result","status") }}: </strong>{{ getMyLanguage("status", dataModal.status) }}</p>
                            <p><strong>{{ getMyLanguage("list-result","DOB") }}: </strong>{{dateTime(dataModal.personDOB)}}</p>
                            
                        </div>
                        
                        <div class="box resume-parent">
                            <p><strong>{{ getMyLanguage("list-result","parentAgent") }}: </strong>{{dataModal.parentName}}</p>
                            <p><strong>{{ getMyLanguage("list-result","agentType") }}: </strong>{{dataModal.parentAgentType}}</p>
                            <p><strong>{{ getMyLanguage("list-result","region") }}: </strong>{{dataModal.parentRegionName}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="this.modalShow === true"></div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import getMyFavouriteLanguage from "@/services/language.js";


    import moment from 'moment';
    // import apiBackoffice from '@/config/apiBackoffice.js'

    export default {
        components: {
            Sidebar
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,

                filter: {

                    // FILTER DATE
                    filterDate: {
                        today: {
                            lbl: 'today',
                            val: 'tdtdtd'
                        },
                        yesterday: {
                            lbl: 'yesterday',
                            val: 'ydydyd'
                        },
                        last7days: {
                            lbl: 'last7days',
                            val: '777777'
                        },
                        last30days: {
                            lbl: 'last30days',
                            val: '303030'
                        },
                        thisMonth: {
                            lbl: "thisMonth",
                            val: 'tmtmtm'
                        },
                        lastMonth: {
                            lbl: "lastMonth",
                            val: 'lmlmlm'
                        },
                        customDate: {
                            lbl: "customDate",
                            val: 'cdcdcd'
                        }
                    },

                    selectedDate: {
                        lbl: "",
                        val: "",
                    },

                    customFilterDate: false,
                    optionsDate: false,
                    closingDate: false,


                    // TYPE TRANSACTION
                    filterTransaction: {
                        all: {
                            lbl: 'allTransactions',
                            val: 'all'
                        },
                        deposit: {
                            lbl: "deposit",
                            val: "deposit"
                        },
                        withdrawal: {
                            lbl: "withdrawal",
                            val: "2ithdrawal"
                        },
                        paymentSent: {
                            lbl: "paymentSent",
                            val: "paymentSent"
                        },
                        paymentRequest: {
                            lbl: "paymentRequest",
                            val: "paymentRequest"
                        },
                        paymentReceived: {
                            lbl: "paymentReceived",
                            val: "paymentReceived"
                        },
                        exchanges: {
                            lbl: "exchanges",
                            val: "exchanges"
                        },
                        dneSent: {
                            lbl: "dneSent",
                            val: "dneSent"
                        },
                        dneReceived: {
                            lbl: "dneReceived",
                            val: "dneReceived"
                        }
                    },

                    selectedTransaction: {
                        lbl: "",
                        val: "",
                    },

                    optionsTransactions: false,
                    closingTransactions: false,


                    // FILTER STATUS
                    filterStatus: {
                        pending: {
                            lbl: "pending",
                            val: "pending"
                        },
                        successfully: {
                            lbl: "complete",
                            val: "complete"
                        },
                        canceled: {
                            lbl: "canceled",
                            val: "canceled"
                        }
                    },

                    selectedStatus: {
                        lbl: "",
                        val: "",
                    },

                    optionsStatus: false,
                    closingStatus: false,


                    // FILTER CURRENCY
                    filterCurrency: {
                        dne: {
                            lbl: "dne",
                            val: "dne"
                        },
                        euro: {
                            lbl: "euro",
                            val: "euro"
                        },
                        dolar: {
                            lbl: "dollar",
                            val: "dollar"
                        },
                        usdt: {
                            lbl: "usdt",
                            val: "usdt"
                        }
                    },

                    selectedCurrency: {
                        lbl: "",
                        val: "",
                    },

                    optionsCurrency: false,
                    closingCurrency: false
                },

                dataTransactions: {},

                dataModal: {},
                modalShow: false,

                errorsGet: {
                    dataSettings: false
                },
                errorsPost: {
                    dataSettings: false
                }
            }
        },

        async mounted () {
            setTimeout(() => {
                this.showContent = true
            }, 1000);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YY/MM/DD' : 'DD/MM/YY';
                return moment(value).format(format);
            },

            dateTimeFull(value) {
                return moment(value).language(this.flagSelected).format('LL');
            },

            optionsDate(value){
                if ( value ){
                    this.filter.closingDate = false
                    this.filter.optionsDate = value
                } else{
                    this.filter.closingDate = true
                    setTimeout(() => {
                        this.filter.closingDate = false
                        this.filter.optionsDate = value
                    }, 400);
                }
            },
            
            optionsTransactions(value){
                if ( value ){
                    this.filter.closingTransactions = false
                    this.filter.optionsTransactions = value
                } else{
                    this.filter.closingTransactions = true
                    setTimeout(() => {
                        this.filter.closingTransactions = false
                        this.filter.optionsTransactions = value
                    }, 400);
                }
            },
            
            optionsStatus(value){
                if ( value ){
                    this.filter.closingStatus = false
                    this.filter.optionsStatus = value
                } else{
                    this.filter.closingStatus = true
                    setTimeout(() => {
                        this.filter.closingStatus = false
                        this.filter.optionsStatus = value
                    }, 400);
                }
            },
            
            optionsCurrency(value){
                if ( value ){
                    this.filter.closingCurrency = false
                    this.filter.optionsCurrency = value
                } else{
                    this.filter.closingCurrency = true
                    setTimeout(() => {
                        this.filter.closingCurrency = false
                        this.filter.optionsCurrency = value
                    }, 400);
                }
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="./custom-transactions.scss" lang="scss" scoped />